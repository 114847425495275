.imagebox {
  height: 100vh;
  position: relative;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  padding-bottom: 3rem;

  .grid-property-images {
    display: grid;
    grid-template-columns: 8fr 2fr;
    grid-template-rows: 100%;
    height: 100%;
    gap: 1.25rem;
    position: relative;
    width: 100%;
    // overflow: hidden;

    img {
      border-radius: 30px;
      height: 100%;
      object-fit: cover;
      box-shadow: 0px 60px 60px #d2dbe280;
    }

    .right-side {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 1.25rem;
      height: 100%;
      overflow: hidden;
      border-radius: 30px;
      img {
        width: 100%;
      }
      box-shadow: 0px 60px 60px #d2dbe280;
    }
  }
  .open-images {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    color: $secondary-color-1;
    padding: 10px 20px;
    margin: 2rem;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: 0.2s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: $secondary-color-1;
      color: #fff;
    }
  }
}
@media (max-width: $breakpoint-laptop) {
  .imagebox {
    height: min-content;
    .grid-property-images {
      grid-template-columns: 1fr;
      height: 400px;
      // height: 100%;

      .right-side {
        display: none !important;
      }
    }

    .open-images {
      left: 50%;
      bottom: 0;
      margin: 2rem 0;
      transform: translateX(-50%);
      width: max-content;
    }
  }
}
