    .search-results {
        //   background-color: red;
        //   width: 100%;
        z-index: 999;
        .searchcontainer {
            z-index: 999;
            position: relative;
            width: 100%;
            ul {
                list-style: none;
                margin-bottom: 0;
                position: absolute;
                background-color: #fff;
                // border: 2px solid $secondary-color;
                border-top: none;
                width: 100%;
                left: -2px;
                margin-top: 18px;
                z-index: 99;
                padding: 0;
                .hover-color {
                    border-bottom: 1px solid rgba(216, 216, 216, 0.753);
                    padding: 1rem 0;
                    li {
                        padding-left: 3rem;
                        color: $background-3;
                    }
                    &:hover {
                        background-color: #d4752b;
                        li {
                            color: #fff;
                        }
                    }
                }
            }
            ul li {
                cursor: pointer;
            }
            hr {
                border-top: 1px solid rgba(216, 216, 216, 0.753);
            }
        }
        & form {
            // background-color: yellow;
            // width: 100%;
            margin: 0;
            padding: 0;
        }
        & .filter-types {
            border-radius: 30px;
            // overflow: hidden;
            border: solid thin #fff;
            padding: 1rem;
            background: #ffffff4d 0% 0% no-repeat padding-box;
            border: 1px solid #ffffff;
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
            .primer-div {
                // padding: 1rem;
                position: relative;
                padding: .5rem 1.5rem;
                &.border-left {
                    border-left: solid thin #fff;
                }
                select {
                    padding-right: 3rem;
                }
            }
            &>div:nth-child(1) {
                // padding-right: 20px;
            }
            &>div:nth-child(2) {
                padding-left: 20px;
                border-left: solid thin #fff;
            }
            &>div:nth-child(0) {
                padding-left: 20px;
                border-left: solid thin #fff;
            }
            & select,
            input {
                color: #fff;
                width: 100%;
                outline: none;
                border: solid thin transparent;
                background-color: transparent;
                font-weight: 500;
                font-size: 1.5rem;
                text-align: left;
                appearance: none;
                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
                option {
                    background-color: white;
                    color: black;
                    font-size: 1.5rem;
                }
            }
            input::placeholder {
                color: #fff;
            }
            i {
                position: absolute;
                z-index: -1;
                right: 1.5rem;
                top: calc(50% - 0.6rem);
                width: 0.8rem;
                height: 0.8rem;
                display: block;
                border-left: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate( -45deg);
                transition: all 0.25s ease;
            }
        }
        .input-wrapper {
            background-color: transparent;
            border: solid thin #fff;
            border-radius: 30px;
            padding: 20px 15px 20px 30px;
            &>input,
            .search {
                border: none;
                width: 100%;
                font-weight: 400;
                font-size: 16px;
                background-color: transparent;
                &::placeholder {
                    font-weight: 300;
                    color: #fff;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
        .input-label {
            font-weight: 400;
            font-size: 16px;
            color: #fff;
        }
        .filter-location {
            // margin-top: 1rem;
            background-color: transparent;
            // border-radius: 30px;
            // padding: 20px 15px 20px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border: solid thin #fff;
            color: #fff;
            padding-left: 20px;
            border-left: solid thin #fff;
            & input {
                border: none;
                //   width: 100%;
                margin-left: 0rem;
                font-weight: 400;
                padding: .5rem 0;
                color: #fff;
                background-color: transparent;
                &::placeholder {
                    font-weight: 400;
                    color: #fff;
                }
            }
            & input.search {
                margin-left: 2.1rem;
            }
            & button {
                padding: 0.5rem 2.75rem;
            }
            &>div:nth-child(1) {
                //   width: 100%;
            }
            .code-wrapp {
                input[type="number"] {
                    // width: 95%;
                    @media (min-width: $bt-bp-lg) {
                        margin-left: 2.5rem;
                    }
                }
            }
        }
        .filter-selected {
            color: #fff;
            margin-left: 5px;
        }
    }
    
    @media (max-width: 992px) {
        .search-results {
            padding: 0px 0vh;
            background-color: transparent;
            .filter-types {
                padding: 0rem 0rem;
                background: transparent;
                border: 0px solid #ffffff;
                backdrop-filter: blur(0px);
                margin-bottom: 2rem;
                width: 100%;
                i {
                    top: calc(50% - 1rem);
                }
                &>div:nth-child(1) {
                    justify-content: center;
                }
                &>div:nth-child(2) {
                    border: none;
                }
                .primer-div {
                    padding: 0;
                    &.border-left {
                        border-left: none;
                    }
                }
                & select,
                input {
                    font-size: 1.3rem;
                    background: #ffffff4d 0% 0% no-repeat padding-box;
                    border: 1px solid #ffffff;
                    backdrop-filter: blur(25px);
                    display: block;
                    padding: 1rem 1rem !important;
                    text-align: left;
                    border-radius: 15px;
                    width: 100%;
                    margin-left: 0rem;
                    margin-bottom: 1rem;
                    &:disabled {
                        cursor: not-allowed;
                    }
                    option {
                        font-size: 1rem;
                    }
                }
                .filter-location {
                    border: none;
                    width: 100%;
                    padding: 0;
                    .code-wrapp {
                        width: 100%;
                    }
                    // padding: 1.3rem 1rem;
                    &:nth-last-child(0) {
                        border-bottom: 1px solid white;
                        // padding: 1.4rem 1rem;
                    }
                    input {
                        padding: 1.3rem 1rem;
                        background: #ffffff4d 0% 0% no-repeat padding-box;
                        border: 1px solid #ffffff;
                        backdrop-filter: blur(25px);
                        width: 100%;
                        display: block;
                        border-radius: 15px;
                        margin-bottom: 1rem;
                    }
                    .searchcontainer {
                        .search {
                            width: 100%;
                            margin-left: 0;
                        }
                    }
                }
            }
            .btn-rounded {
                margin: auto !important;
                width: 100%;
                padding: .9rem 1.8rem;
            }
        }
    }