.dark-orange {
    color: $primary-color-1!important;
}

.dark-blue {
    color: $secondary-color-1!important;
}

.light-blue {
    color: $tertiary-color-1!important;
}