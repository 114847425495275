.Resultados {
    .filter {
        padding: 2rem 3rem;
        padding-top: 2.5rem;
        // padding-top: 25rem;
        // border-radius: 10px;
        max-width: 92%;
        background-color: #fff;
        position: static;
        // overflow-y: scroll;
        z-index: 99;
        // max-height: 100vh;
        min-height: 100vh;
        width: 36.5vw;
        top: 0;
        right: -100%;
        border-radius: 30px;
        transition: 0.4s ease all;
        &.open {
            right: 0;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba($secondary-color, 0.7);
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgba($secondary-color, 0.7);
        }
        & hr {
            border-top: solid 3px $background-1;
        }
        &_header {
            h3 {
                font-size: 2.25rem;
                color: $secondary-color-3;
                font-weight: 400;
                margin-bottom: 2rem;
                letter-spacing: -1%;
                opacity: 0.8;
                background-color: transparent;
            }
            button {
                border: none;
                font-size: 1.3rem;
                background-color: transparent;
                color: $background-3;
                font-weight: 600;
            }
            .current-tags {
                margin-top: 2rem;
                .tag {
                    padding: 0.5rem 1.2rem;
                    text-transform: capitalize;
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;
                    font-size: 1.8rem;
                    border: solid thin $secondary-color-3;
                    border-radius: 7px;
                    color: $secondary-color-3;
                    font-weight: 500;
                    opacity: 0.8;
                    .btn-delete-tag {
                        margin-left: 1rem;
                        height: 2.5px;
                        width: 14px;
                        position: relative;
                        cursor: pointer;
                        display: block;
                        background-color: $background-3;
                        transform: rotate(-45deg);
                        &::before {
                            content: "";
                            height: 2.5px;
                            width: 14px;
                            position: absolute;
                            display: block;
                            background-color: $background-3;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            .close-filter {
                border: 2px solid rgba($secondary-color, 1);
                width: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                height: 3.5rem;
                transition: 0.4s ease all;
                padding: 2rem;
                position: absolute;
                top: 5px;
                right: 5px;
                span {
                    width: 1.5rem;
                    height: 2px;
                    background-color: #000000;
                    display: block;
                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: rotate(-45deg) translate(1px, -1px);
                    }
                }
                &:hover {
                    background: $primary-color !important;
                    border: 2px solid $primary-color;
                    span {
                        color: #000;
                    }
                }
            }
        }
        &_body {
            .filters-group {
                border-top: 1px solid #fff;
                h3 {
                    font-size: 2rem;
                    color: $secondary-color-3;
                    font-weight: normal;
                    margin-bottom: 0;
                    opacity: 0.8;
                    text-transform: uppercase;
                    padding-left: 1rem;
                }
                .collapse-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    span {
                        padding: 7px;
                        width: 23px;
                        height: 23px;
                        display: inline-block;
                        position: relative;
                        font-weight: 500;
                        font-size: 0.8rem;
                        line-height: 1;
                        border-radius: 50%;
                        color: #fff;
                        background-color: $secondary-color-2;
                        border-radius: 7px;
                        &:before {
                            width: 11px;
                            height: 2px;
                        }
                        &::after {
                            height: 2px;
                            width: 2px;
                        }
                        &:before,
                        &:after {
                            content: "";
                            background: #fff;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: 0.3s;
                        }
                    }
                    &.collapsed {
                        span {
                            &:after {
                                height: 11px;
                            }
                        }
                    }
                }
                input[type="text"],
                input[type="number"] {
                    color: $background-3;
                    border: 1px solid $secondary-color-1;
                    border-radius: 20px;
                    padding: 1.3rem 1.7rem;
                    background-color: transparent;
                    line-height: 1;
                    font-size: 1.7rem;
                    width: 100%;
                    &::placeholder {
                        color: $background-3;
                    }
                }
                .options {
                    .content-collapse {
                        padding: 1.3rem 0 0.8rem 0;
                        .text-danger {
                            font-weight: 400;
                        }
                        &.non-slider {
                            .noUi-target {
                                opacity: 0.8;
                            }
                        }
                        .noUi-target {
                            border: none;
                        }
                        .noUi-horizontal {
                            height: 2px;
                        }
                        .noUi-connect {
                            height: 3px;
                            background: $secondary-color-2;
                        }
                        .noUi-connects {
                            background-color: $secondary-color-1;
                        }
                        .noUi-horizontal {
                            .noUi-handle {
                                width: 19px;
                                border-radius: 50%;
                                height: 19px;
                                top: -9px;
                                background: $secondary-color-2;
                                border: none;
                                outline: none !important;
                                box-shadow: none;
                                &:after {
                                    display: none;
                                }
                                &:before {
                                    height: 9px;
                                    width: 9px;
                                    background: $background-3;
                                    left: 5px;
                                    top: 5px;
                                    border-radius: 50%;
                                }
                                &.noUi-handle-upper {
                                    right: -3px;
                                }
                                &.noUi-handle-lower {
                                    right: -17px;
                                }
                            }
                            .noUi-tooltip {
                                background: transparent;
                                border: none;
                                top: 120%;
                                font-size: 0.8rem;
                            }
                        }
                        .option {
                            display: flex;
                            margin-bottom: 0.67rem;
                            color: $secondary-color-3;
                            cursor: pointer;
                            font-size: 1.5rem;
                            align-items: center;
                            font-weight: 100;
                            // font-style: normal;
                            // opacity: 0.8;
                            input {
                                display: none;
                                &:checked+span {
                                    background: $secondary-color-3;
                                    border: 0;
                                    &:before {
                                        background: rgba($background-3, 1);
                                        transform: translate(-50%, -50%) scale(1.5);
                                        // border-radius: 50%;
                                    }
                                }
                            }
                            span {
                                display: inline-block;
                                vertical-align: middle;
                                position: relative;
                                margin-right: 0.9rem;
                                width: 25px;
                                height: 25px;
                                border: 1px solid $secondary-color-3;
                                background: transparent;
                                // border-radius: 50%;
                                &:before {
                                    content: "";
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    transition: 0.3s;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%) scale(0);
                                }
                            }
                            .circle {
                                border-radius: 50%;
                                &::before {
                                    border-radius: 50%;
                                }
                            }
                        }
                        .selected {
                            font-weight: 400;
                            font-style: normal;
                            color: $secondary-color-3;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1400px) {
    .Resultados .filter_body .filters-group h3 {
        font-size: 1.8rem;
    }
}

@media (max-width: 992px) {
    .Resultados {
        .filter_header {
            .close-filter {
                border: none !important;
            }
        }
        .close-filter {
            display: block;
            span {
                width: 1.5rem;
                height: 2px;
                background-color: #000000;
                color: #fff;
                // margin-bottom: 5px;
                display: block;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg) translate(1px, -1px);
                }
            }
        }
        .filter {
            position: fixed;
            top: 0%;
            bottom: 0;
            left: 200%;
            right: 200%;
            z-index: 9999999;
            max-width: 100%;
            border: none;
            border-radius: 0;
            overflow: scroll;
            padding: 5rem 1rem;
            width: 100%;
            height: 100vh;
            transition: 0.3s ease all;
            &.open {
                right: 0;
                left: 0;
            }
            .filters-group {
                h3 {
                    font-size: 1.2rem;
                }
                .options .content-collapse .option {
                    font-size: 0.85rem;
                    letter-spacing: 0.7px;
                }
            }
        }
    }
}