.Inicio {
    .about {
        background-color: $background-1;
        padding: 10rem 0 14rem 0;
        .title-1 {
            font-size: 6rem;
        }
        .paragraph-1 {
            font-size: 1.7rem;
            margin-top: 7rem;
            margin-bottom: 10rem;
            font-weight: normal !important;
            color: $secondary-color-2 !important;
        }
        .logo-rotation {
            z-index: 111;
            position: absolute;
            top: -12%;
            left: 42%;
        }
        & .about-image {
            width: 97%;
            height: 100%;
            border-radius: 30px;
            object-fit: cover;
            box-shadow: 0px 60px 60px #D1D1D186;
            mix-blend-mode: multiply;
            object-position: center;
        }
        @media (max-width: $breakpoint-tablet) {
            padding-bottom: 10rem;
            & .about-image {
                min-height: 300px;
            }
        }
        & .description {
            // margin-top: 3rem;
            align-self: self-end;
            align-items: end;
            a {
                margin-top: 1rem;
                .btn-rounded {
                    padding: 1.25rem 4rem;
                    font-weight: 500;
                }
            }
        }
        & .quote {
            position: absolute;
            top: 20px;
            left: -65px;
        }
    }
}

@media (max-width: 1600px) {
    .Inicio {
        .about {
            .div-pad {
                .title-1 {
                    font-size: 4rem;
                }
                .paragraph-1 {
                    font-size: 1.5rem;
                    margin-top: 6rem;
                    margin-bottom: 6rem;
                }
            }
            .logo-rotation {
                top: -14%;
                left: 40%;
            }
        }
    }
}

@media (max-width: 1450px) {
    .Inicio {
        .about {
            .div-pad {
                padding-left: 8rem;
                .title-1 {
                    font-size: 4rem;
                }
                .paragraph-1 {
                    font-size: 1.5rem;
                    margin-top: 6rem;
                    margin-bottom: 6rem;
                }
            }
            .logo-rotation {
                top: -14%;
                left: 40%;
            }
        }
    }
}

@media (max-width: 1150px) {
    .Inicio {
        .about {
            .logo-rotation {
                top: -14%;
                left: 37%;
            }
        }
    }
}

@media (max-width: 1150px) {
    .Inicio {
        .about {
            .description {
                .div-pad {
                    padding: 1rem;
                    display: block !important;
                    text-align: left;
                    .title-1 {
                        font-size: 2.7rem;
                        margin-top: 3rem;
                    }
                    .paragraph-1 {
                        margin-top: 3rem;
                    }
                    p {
                        font-size: 1.3rem;
                    }
                    a {
                        .btn-rounded {
                            font-size: 1.5rem;
                            width: fit-content;
                            padding: 1rem 2rem;
                        }
                    }
                }
            }
        }
    }
}