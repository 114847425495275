.contact-main {
    width: 100%;
    input,
    textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: .3rem;
        border-radius: 5px;
        padding: 1.5rem 2.3rem;
        border: 1px solid #000;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #1d1d1d;
        }
    }
}

@media (max-width:992px) {
    .contact-main {
        input,
        textarea {
            font-size: 1rem;
            margin-bottom: 1.5rem !important;
            display: block;
            padding: 0.6rem;
            font-weight: 300;
            &::placeholder {
                color: #6a6a6a;
            }
        }
        textarea {
            height: min-content !important;
        }
        button {
            margin-top: 1rem;
            font-size: 1.2rem;
        }
    }
}