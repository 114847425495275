.emprendimientos {
    .main {
        height: 100vh;
        overflow: hidden;
        position: relative;
        background-color: $background-1;
        .content-image {
            position: relative;
            height: 85vh;
            transition: .4s ease all;
            overflow: hidden;
            border-radius: 0 0 30px 30px;
            width: 100%;
            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                width: 100%;
            }
            // &::before {
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     right: 0;
            //     bottom: 0;
            //     content: '';
            //     background: linear-gradient(180deg, rgba(0, 0, 0, 0.751278182171306) 0%, rgba(115, 115, 115, 0) 40%, rgba(255, 255, 255, 0) 100%);
            //     opacity: 1;
            //     z-index: 9;
            //     display: block;
            // }
        }
        .content-slider {
            position: absolute;
            bottom: -2vh;
            left: 50%;
            transform: translateX(-50%);
            z-index: 9;
            width: max-content;
            height: 15vh;
            margin: auto 0;
            text-align: center;
            .item {
                position: relative;
                // margin-right: 7vw;
                z-index: 9;
                h3 {
                    span {
                        font-style: 1.5rem;
                        color: #0F0F0F;
                        font-weight: 300;
                    }
                }
                .arrows {
                    display: none;
                    cursor: pointer;
                    .next,
                    .back {
                        // border-radius: 50%;
                        background-color: #fff;
                        padding: 10px;
                        i {
                            font-size: 30px;
                        }
                        &:hover, &:focus{
                            background-color: #000;
                        }
                    }
                }
                h5,
                a {
                    display: none;
                }
                &.active {
                    padding: 2rem 2.5rem;
                    width: 70vw;
                    // height: 24rem;
                    position: relative;
                    background: #fff;
                    color: #000;
                    margin-bottom: 13.13rem;
                    display: inline-block;
                    border-radius: 30px;

                    & > div{
                        margin-left: 4.5rem;
                        width: 100%;
                        text-align: start;
                    }

                    .line-location {
                        width: 1.5px;
                        height: 15px;
                        background-color: $border-color-1;
                    }
                    h3 {
                        font-size: 2.25rem;
                        display: block;
                        font-weight: 300;
                        width: 110%;
                        font-weight: 500;
                        margin: 0;
                    }
                    h5 {
                        font-size: 1rem;
                        display: block;
                        // text-transform: uppercase;
                        letter-spacing: 1px;
                        font-weight: 500;
                        color: $border-color-1;
                        margin: 0;
                        i{
                            color: $border-color-1;                            
                        }
                    }
                    a {
                        display: block;
                        font-size: 1.5rem;
                        font-weight: 400;
                        color: $primary-color-1;
                        text-decoration: none;
                        text-decoration: underline;
                        padding-bottom: 0;
                        margin-bottom: 0;
                        transition: .2s ease all;
                        &:hover{
                            color: $primary-color;
                        }
                    }
                    .arrows {
                        display: block;
                        opacity: 1;
                        .next {
                            // width: 3.5rem;
                            // background-color: $secondary-color;
                            // color: #fff;
                            // height: 3.5rem;
                            // font-size: 1.2rem;
                            position: absolute;
                            right: 2rem;
                            top: calc(50% - 1.5rem);
                        }
                        .back {    
                            transform: rotate(180deg);            
                            position: absolute;
                            left: 2rem;
                            top: calc(50% - 1.5rem);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:992px) {
    .emprendimientos {
        .full-container {
            max-width: 95%;
            margin-left: auto;
        }
    }
}

@media (max-width:992px) {
    .emprendimientos {
        .main {
            .content-slider {
                // left: 50%;
                // transform: translateX(-50%);
                .item {
                    // margin-left: 1rem;
                    h5,
                    a {
                        display: none;
                    }
                    &.active {
                        // padding: 1.35rem 2rem;
                        // width: 15rem;
                        // height: 1rem;
                        // margin-bottom: 20rem;
                        width: 100vw;
                        & > div{
                            margin-left: 0;
                            text-align: center;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                        &::before {
                            z-index: -1;
                        }
                        h3 {
                            font-size: 1.8rem;
                            margin-bottom: 1.5rem;
                            width: calc(100% + 3.5rem);
                        }
                        h5 {
                            font-size: .8rem;
                            // .line-location {
                            //     width: 3rem;
                            // }
                        }
                        a {
                            // margin-top: 4rem;
                            font-size: .8rem;
                        }
                        .arrows {
                            display: block;
                            opacity: 1;
                            .next {
                                top: -25px;
                                // width: 2.5rem;
                                // height: 2.5rem;
                                // background-color: $secondary-color;
                                // color: #fff;
                                // font-size: 1rem;
                            }
                            .back {
                                top: -25px;
                                // width: 2.5rem;
                                // height: 2.5rem;
                                // background-color: $secondary-color;
                                // color: #fff;
                                // font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}