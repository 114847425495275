.mapa {
    background-color: $background-1;
    padding: 15rem 0 13rem 0;
    .mancha-after7::after {
        left: 65%;
        top: 50px;
    }
    h1.title-1 {
        font-size: 4rem;
        line-height: 4.5rem;
    }
    p.paragraph-1 {
        margin: 2rem 0 5rem 0;
        max-width: 400px;
    }
    .map-2 {
        margin-top: 6rem;
    }
    .image1,
    .image2 {
        border-radius: 30px;
        overflow: hidden;
        div {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .image1 {
        height: 800px;
        div {
            // animation: rotating 20s alternate infinite linear;
        }
    }
    .image2 {
        width: 100%;
        height: 500px;
        padding: 3rem;
        background: #fff;
        div {
            background-size: contain;
        }
    }
    .compass {
        top: 10px;
        width: 75px;
        right: 10px;
        position: absolute;
        margin: 1rem 1rem 0 0;
    }
}

@keyframes rotating {
    0% {
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -ms-transform: scale(1.25);
        -moz-transform: scale(1.25);
        -webkit-transform: scale(1.25);
        -o-transform: scale(1.25);
        transform: scale(1.25);
    }
}