.contact-main {
    padding: 10vh 0;
    background-color: transparent;
    .content-contact {
        width: 100%;
        span,
        a {
            width: 100%;
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
            color: $border-color-1;
            font-weight: 400;
            border-bottom: none;
            transition: 0.3s;
        }
        a {
            &:hover {
                color: $primary-color;
            }
        }
    }
    form {
        padding-top: 5vh;
        padding-bottom: 5vh;
        textarea,
        input {
            border: none;
            color: #000;
            padding: 0rem 0;
            font-size: 20px;
            &::placeholder {
                color: $border-color-1;
                font-weight: 500;
                font-style: normal;
            }
        }
        .input-wrapper {
            background-color: #fff;
            width: 100%;
            border-radius: 30px;
            padding: 1.25rem 0rem 1.25rem 3rem;
            box-shadow: 0px 40px 60px #d2dbe266;
            label {
                color: $secondary-color-2;
                font-weight: 600;
                font-style: normal;
                font-size: 1.45rem;
            }
             ::placeholder {
                color: $secondary-color-2;
            }
        }
        .p-obligatorio {
            color: $secondary-color-2;
            font-size: 1.45rem;
            font-weight: 600;
            font-style: normal;
        }
        textarea {
            height: 100%;
            resize: none;
            font-weight: 500;
            font-style: normal;
            font-size: 1.35rem;
            color: $secondary-color-2 !important;
        }
        .btn-rounded {
            font-size: 2rem;
            padding: 1.5rem 4.7rem;
            font-weight: 600;
            font-style: normal;
            box-shadow: 0px 20px 40px #00000029;
            border-radius: 23px;
        }
    }
    .segundo-div {
        border-radius: 38px;
        border: 1px solid $secondary-color-3;
        padding: 1.5rem;
        .primer-bloque-info {
            //   margin-top: 1rem;
            span {
                font-size: 1.45rem;
                font-weight: 600;
                font-style: normal;
                color: $secondary-color-1;
            }
            p {
                font-size: 1.45rem;
                display: flex;
                align-items: baseline;
                margin: 0;
                font-weight: normal;
                font-style: normal;
                text-decoration: none;
                text-transform: none;
                color: $secondary-color-1;
                margin-bottom: 0.5rem;
                i {
                    font-size: 1rem;
                    color: #0c446f;
                    margin-right: 1rem;
                }
                a {
                    text-decoration: none;
                    text-transform: none;
                    color: $secondary-color-1;
                    font-weight: normal;
                    font-style: normal;
                }
            }
        }
        .segundo-bloque-info {
            //   margin-top: 1rem;
            span {
                font-size: 1.45rem;
                font-weight: 600;
                font-style: normal;
                color: $secondary-color-1;
            }
            p {
                font-size: 1.45rem;
                display: flex;
                align-items: baseline;
                margin: 0;
                font-weight: normal;
                font-style: normal;
                text-decoration: none;
                text-transform: none;
                color: $secondary-color-1;
                margin-bottom: 0.5rem;
                i {
                    font-size: 1rem;
                    color: #0c446f;
                    margin-right: 1rem;
                }
                a {
                    text-decoration: none;
                    text-transform: none;
                    color: $secondary-color-1;
                    font-weight: normal;
                    font-style: normal;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .contact-main {
        padding: 2vh 0;
        .content-contact {
            width: 100%;
            span,
            a {
                font-size: 1.1rem;
                margin-bottom: 0.6rem;
            }
            .line-phone {
                width: 15vw;
            }
        }
        form {
            padding: 5vh 0 !important;
            textarea {
                height: 100%;
            }
            .btn-rounded {
                width: 100%;
                justify-content: center;
                padding: 1rem;
            }
            p {
                font-size: 0.8rem;
                margin-bottom: 2rem;
            }
            input,
            textarea {
                margin-bottom: 0 !important;
                font-size: 1.4rem;
                font-weight: 500;
                font-style: normal;
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                /*sirve para que a la hora de seleccionar una opcion guardada no muestre color de fondo y el color de la letra sea parecida a la anterior  */
                transition: background-color 5000s ease-in-out 0s;
                -webkit-box-shadow: 0 0 0px 1000px unset;
                -webkit-text-fill-color: black;
            }
        }
        .segundo-div {
            margin-top: 3rem;
            margin-bottom: 3rem;
            .primer-bloque-info {
                span {}
                p {
                    font-size: 1.2rem;
                    i {}
                    a {}
                }
            }
            .segundo-bloque-info {
                span {}
                p {
                    font-size: 1.2rem;
                    i {}
                    a {}
                }
            }
        }
    }
}