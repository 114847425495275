.tasaciones {
    .main {
        overflow: hidden;
        padding-top: 15rem;
        padding-bottom: 5rem;
        background-color: $background-1;
        .title-1 {
            font-size: 6rem;
            color: $secondary-color-2;
            font-weight: 600;
            margin-top: 2rem;
            font-style: normal;
        }
        .subrayado {
            border-top: 1px solid $secondary-color-2;
            margin: 2rem 1rem;
            margin-bottom: 1rem;
            p {
                text-align: end;
                font-size: 1.2rem;
                margin-top: .5rem;
                color: $secondary-color-2;
            }
        }
        @media (min-width: $bt-bp-lg) and (max-width: 1078px) {
            .title-1 {
                font-size: 4.5rem;
                line-height: 5rem !important;
            }
        }
        .title-description {
            font-size: 1.65rem;
            max-width: 400px;
            font-weight: normal;
            font-style: normal;
            color: $secondary-color-2;
        }
        .tick {
            background: $tertiary-color;
            width: 8rem;
            border-radius: 100%;
            margin-bottom: 2rem;
            height: 8rem;
            i {
                color: #0f0f0f;
                font-size: 2rem;
            }
        }
        .formulario {
            .content-form {
                width: 100%;
                margin: auto;
                padding-bottom: 10vh;
                .search-results {
                    .input-wrapper {
                        background: var(--unnamed-color-f3edec) 0% 0% no-repeat padding-box;
                        border: 1px solid var(--unnamed-color-0c446f);
                        background: #f3edec 0% 0% no-repeat padding-box;
                        box-shadow: 0px 20px 60px #dbdada;
                        border: 1px solid #0c446f;
                        border-radius: 41px;
                        opacity: 1;
                        padding: 2rem 3rem;
                        label {
                            color: $secondary-color-2;
                            font-size: 1.5rem;
                            font-weight: 600;
                            font-style: normal;
                        }
                        input {
                            color: black;
                            font-size: 1.3rem;
                            font-weight: 500;
                            font-style: normal;
                            &::placeholder {
                                color: $secondary-color-2;
                                font-size: 1.3rem;
                                font-weight: 500;
                                font-style: normal;
                            }
                        }
                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        input:-webkit-autofill:active {
                            /*sirve para que a la hora de seleccionar una opcion guardada no muestre color de fondo y el color de la letra sea parecida a la anterior  */
                            transition: background-color 5000s ease-in-out 0s;
                            -webkit-box-shadow: 0 0 0px 1000px unset;
                            -webkit-text-fill-color: #005898;
                        }
                    }
                    .filter-types {
                        background: var(--unnamed-color-f3edec) 0% 0% no-repeat padding-box;
                        border: 1px solid var(--unnamed-color-0c446f);
                        background: #f3edec 0% 0% no-repeat padding-box;
                        box-shadow: 0px 20px 60px #dbdada;
                        border: 1px solid #0c446f;
                        opacity: 1;
                        padding: 2rem 3rem;
                        border-radius: 41px;
                        i {
                            color: $secondary-color-3 !important;
                            font-size: 1.2rem;
                        }
                        span {
                            color: $secondary-color-2;
                            font-size: 1.45rem;
                            font-weight: 600;
                            font-style: normal;
                        }
                        select {
                            color: $secondary-color-2;
                            font-weight: 500;
                            font-style: normal;
                            font-size: 1.45rem;
                        }
                        div:first-child {
                            padding-right: 1rem;
                            width: 50%;
                        }
                        div:last-child {
                            border-color: $secondary-color-3 !important;
                            width: 50%;
                        }
                        .surface1,
                        .surface2 {
                            label {
                                color: $secondary-color-2;
                                color: $secondary-color-2;
                                font-size: 1.45rem;
                                font-weight: 600;
                                font-style: normal;
                                padding-right: 1rem;
                            }
                            input {
                                color: $secondary-color-2;
                                font-weight: 500;
                                font-style: normal;
                                font-size: 1.45rem;
                                &::placeholder {
                                    color: $secondary-color-2;
                                    font-size: 1.3rem;
                                    font-weight: 500;
                                    font-style: normal;
                                }
                            }
                            input:-webkit-autofill,
                            input:-webkit-autofill:hover,
                            input:-webkit-autofill:focus,
                            input:-webkit-autofill:active {
                                /*sirve para que a la hora de seleccionar una opcion guardada no muestre color de fondo y el color de la letra sea parecida a la anterior  */
                                transition: background-color 5000s ease-in-out 0s;
                                -webkit-box-shadow: 0 0 0px 1000px unset;
                                -webkit-text-fill-color: #005898;
                            }
                        }
                        .surface2 {
                            padding-left: 1rem;
                        }
                    }
                    .div-cantidad-ambientes {
                        label {
                            font-size: 2rem;
                            color: $secondary-color-2;
                            font-weight: 600;
                            font-style: normal;
                            align-self: flex-start;
                        }
                        .option {
                            display: inline-block;
                            input {
                                display: none;
                                &:checked+label {
                                    span {
                                        background-color: $secondary-color-1;
                                        color: white;
                                        &:before {
                                            background: $primary-color;
                                            transform: translate(-50%, -50%) scale(1.15);
                                        }
                                    }
                                }
                            }
                            label {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                color: $secondary-color-1;
                                margin-top: 0;
                                span {
                                    display: flex;
                                    align-items: center;
                                    color: $secondary-color-1;
                                    justify-content: center;
                                    vertical-align: middle;
                                    position: relative;
                                    height: 3.5rem;
                                    width: 3.5rem;
                                    line-height: 3.5rem;
                                    // padding: 10px 15px;
                                    background-color: transparent;
                                    border-radius: 18px;
                                    border: solid thin $secondary-color-1;
                                    margin-bottom: 0;
                                    cursor: pointer;
                                    font-weight: 500;
                                    &:hover {
                                        background-color: $secondary-color-1;
                                        color: #fff;
                                    }
                                    &:before {
                                        content: "";
                                        display: inline-block;
                                        transition: 0.3s;
                                        position: absolute;
                                        color: $secondary-color-1;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%) scale(0);
                                    }
                                }
                                .square {
                                    border-radius: 0;
                                    &::before {
                                        border-radius: 0;
                                    }
                                }
                            }
                        }
                        &.div-antiguedad {
                            .option {
                                label {
                                    span {
                                        width: max-content;
                                        padding: 1rem;
                                        font-size: 1.35rem;
                                    }
                                }
                            }
                        }
                    }
                    .div-antiguedad,
                    .div-caracteristicas {
                        label {
                            font-size: 2rem;
                            color: $secondary-color-2;
                            font-weight: 600;
                            font-style: normal;
                            align-self: flex-start;
                        }
                    }
                    .btn-send {
                        padding: 1rem 4rem !important;
                        font-size: 2rem;
                        font-weight: 600;
                        font-style: normal;
                        margin-top: 2rem !important;
                    }
                    // .option-check {
                    //   margin-bottom: 0.5rem !important;
                    //   // font-family: "Banana Grotesk" !important;
                    //   input {
                    //     display: none;
                    //     &:checked + label {
                    //       span {
                    //         background: rgba($color: $primary-color, $alpha: 1);
                    //         color: #fff;
                    //         border: 0;
                    //         &:before {
                    //           background: #fff;
                    //           transform: translate(-50%, -50%) scale(1.15);
                    //         }
                    //       }
                    //     }
                    //   }
                    //   label {
                    //     margin-right: 6rem;
                    //     span {
                    //       width: 22px;
                    //       height: 22px;
                    //       border: 1px solid rgba($color: #fff, $alpha: 0.5);
                    //       background: transparent;
                    //       border-radius: 50%;
                    //       margin-right: 0.5rem;
                    //       &:before {
                    //         content: "";
                    //         width: 9px;
                    //         height: 9px;
                    //         transition: 0.3s;
                    //         border-radius: 50%;
                    //       }
                    //     }
                    //   }
                    // }
                }
            }
        }
    }
}

@media (min-width: $breakpoint-laptop) {
    .tasaciones {
        .main {
            .full-fluid {
                max-width: 95%;
                margin-left: auto;
                padding-right: 0;
                margin-right: 0;
                position: relative;
            }
        }
    }
}

@media (max-width: $breakpoint-laptop) {
    .tasaciones {
        .main {
            min-height: 90vh;
            padding-top: 13rem;
            overflow: hidden;
            .full-fluid {
                min-height: 90vh;
            }
            .title-1 {
                line-height: 1 !important;
                font-size: 3rem;
                text-align: left;
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
            .title-description {
                font-size: 1.3rem;
                text-align: left;
                margin-bottom: 2rem;
            }
            &.step-0 {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    height: 90vh;
                    background-color: $secondary-color;
                    opacity: 0.6;
                    z-index: 9;
                    content: "";
                }
            }
            .primary-row {
                min-height: 90vh;
                &.bg-transparent {
                    &::before {
                        background-color: transparent;
                    }
                }
                .left-column {
                    position: relative;
                    z-index: 10;
                    padding-top: 4rem;
                    span {
                        margin-bottom: 1rem;
                        font-size: 1rem;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        // color: #fff;
                    }
                    h2 {
                        margin-bottom: 1.8rem;
                    }
                    &.step-1-column {
                        padding-top: 5vh;
                        z-index: 99;
                        span,
                        h2 {
                            color: #fff;
                        }
                    }
                }
                img {
                    height: 90vh;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }
            .content-form {
                padding: 0;
                width: 100%;
                z-index: 99;
                position: relative;
                .option-check {
                    label {
                        margin-right: 0;
                        text-transform: capitalize;
                        font-size: 0.9rem;
                    }
                }
                i {
                    top: calc(50% - 1rem);
                }
                .btn {
                    margin-bottom: 10vh;
                    margin-top: 3vh !important;
                }
                p {
                    font-size: 0.9rem;
                    // color: #000;
                }
                .border-bottom-name {
                    margin: 2rem 0 2.5rem 0;
                }
            }
            .tick {
                width: 5rem;
                margin-bottom: 2rem;
                height: 5rem;
                i {
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
            .btn {
                display: block;
                margin-top: 3vh;
                padding-top: 1rem;
                padding-bottom: 0.8rem;
                line-height: 1;
                font-size: 0.9rem;
            }
            &.step-2 {
                min-height: 90vh;
                padding-bottom: 0vh;
                // display: flex;
                // align-items: center;
                // .full-fluid {
                .primary-row {
                    padding-bottom: 4rem;
                }
                .row {
                    background: transparent !important;
                    padding-top: 0vh;
                    h2,
                    .text-thanks {
                        font-size: 2rem !important;
                        font-weight: 300;
                    }
                }
                // }
            }
            .formulario {
                .content-form {
                    .search-results {
                        .input-wrapper {
                            padding: 1rem 2rem;
                            label {
                                font-size: 1.2rem;
                            }
                            input {
                                &::placeholder {}
                            }
                        }
                        .filter-types {
                            padding: 1rem 1rem;
                            text-align-last: left;
                            i {
                                display: none;
                            }
                            span {
                                font-size: 1.2rem;
                            }
                            select {
                                font-size: 1.2rem;
                                width: 100% !important;
                            }
                            div {
                                width: 100% !important;
                                padding: 0 1rem;
                            }
                            div:last-child {
                                border: none;
                            }
                            .surface1,
                            .surface2 {
                                label {
                                    font-size: 1.2rem;
                                }
                                input {
                                    width: 100%;
                                    &::placeholder {}
                                }
                            }
                            .surface2 {}
                        }
                        .div-cantidad-ambientes {
                            label {
                                // font-size: 1.2rem;
                            }
                            .option {
                                input {
                                    &:checked+label {
                                        span {
                                            &:before {}
                                        }
                                    }
                                }
                                label {
                                    span {
                                        width: 3rem;
                                        height: 3rem;
                                        border-radius: 12px;
                                        &:hover {}
                                        &:before {}
                                    }
                                    .square {
                                        &::before {}
                                    }
                                }
                            }
                            &.div-antiguedad {
                                .option {
                                    label {
                                        span {}
                                    }
                                }
                            }
                        }
                        .div-antiguedad,
                        .div-caracteristicas {
                            label {}
                        }
                        .btn-send {}
                    }
                }
            }
        }
    }
}