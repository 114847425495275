.main-home {
    background-color: $background-1;
    position: relative;
    z-index: 1;
    min-height: 100vh;
    display: flex;
    .container-fluid {
        z-index: 2;
        .main {
            display: flex;
            justify-content: start;
            align-items: start;
            min-height: 100vh;
            padding-top: 14rem;
            z-index: 2;
            &>div {}
            .contenedor-header {
                align-self: center;
                .main-left {
                    .title-1 {
                        margin-bottom: 10rem;
                        color: white;
                        font-weight: 500;
                        font-size: 6rem;
                        line-height: 1 !important;
                    }
                }
            }
        }
    }
    .main-slider {
        .background {
            background: #000000 0% 0% no-repeat padding-box;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.29;
            z-index: 3;
        }
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        border-bottom: 10px solid $tertiary-color-1;
        .h-100 {
            width: 100%;
        }
        .owl-carousel {
            height: 100vh;
        }
        .owl-carousel .owl-stage {
            display: flex;
        }
        .owl-carousel .owl-item img {
            height: 100vh;
            object-fit: cover !important;
            object-position: center;
        }
        .owl-carousel .owl-item {
            height: 100%;
        }
        .owl-dots {
            position: absolute;
            opacity: 1; //OK
            bottom: 12%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 99;
            .owl-dot {
                span {
                    background-color: #ffffff !important;
                    border-radius: 4px !important;
                    height: 14px !important;
                    width: 14px !important;
                    // border: 0.5px solid $secondary-color-1;
                }
                &.active {
                    span {
                        background-color: $background-3 !important;
                        border: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .main-home {
        // background-image: url(../../images/home3.jpg);
        z-index: 1;
        .container-fluid {
            .main {
                padding-top: 10rem;
                display: block !important;
                &>div {}
                .contenedor-header {
                    height: calc(85vh - 8rem);
                    .main-left {
                        height: 100%;
                        padding-bottom: 3vh;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .title-1 {
                            font-size: 3rem;
                            margin-top: 2rem;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
        .main-slider {
            .owl-dots {
                bottom: 5vh;
                &>.owl-dot>span {
                    width: 10px !important;
                    height: 10px !important;
                }
                &>button.active span {}
            }
        }
    }
}