.footer-white {
    background-color: $background-1;
    & footer {
        background-color: #fff;
    }
    header {
        background-color: #fff;
        border-bottom: none;
        img {
            width: 10rem;
        }
        &::before {
            display: none;
        }
    }
    .content-newsletter form {
        background-color: $background-2;
    }
}

.footer-home {
    background-color: $background-1;
    & footer {
        background-color: $background-1;
        box-shadow: 40px -20px 60px #dbdada;
    }
    header {
        &::before {
            display: none;
        }
        border-bottom: none;
        img {
            height: 10rem;
        }
        nav {
            ul {
                li {
                    a {
                        font-weight: normal;
                    }
                    &.iconos-redes {
                        display: none !important;
                    }
                }
            }
        }
    }
    .content-newsletter form {
        background-color: $background-1;
        border: 1px solid $secondary-color-1;
    }
}

footer {
    background-color: $background-1;
    // width: 90vw;
    border-radius: 55px 55px 0 0;
    margin: auto;
    padding-top: 3rem;
    padding-bottom: 2rem;
    z-index: 999;
    position: relative;
    // &::before {
    //     content: '';
    //     position: absolute;
    //     right: 0;
    //     top: -31vh;
    //     height: 32vh;
    //     width: 30vh;
    //     background: linear-gradient( 56deg, transparent 60%, #fff 0%);
    //     transform: rotate( 90deg);
    // }
    .div-bloque-uno {
        padding-right: 7rem;
        .footer-login-button {
            width: 170px;
            // height: 50px;
            border-radius: 16px;
            border: 0.5px solid $secondary-color-1;
            display: grid;
            grid-template-columns: 1fr 2fr;
            align-items: center;
            justify-content: center;
            padding: 0;
            position: relative;
            margin: 0;
            overflow: hidden;
            & img {
                width: 15px;
                height: 15px;
            }
            & div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            & div:nth-child(2) {
                //   border-left: 0.75px solid #202038;
                & a {
                    color: #000;
                    height: 100%;
                    width: 100%;
                    padding-top: 12px;
                    padding-left: 30px;
                    font-weight: 500;
                }
                &:hover {
                    background-color: #000;
                    & a {
                        color: #fff;
                    }
                }
            }
            &.matricula {
                //   grid-template-columns: 2fr 1fr;
                width: 15rem;
                margin-top: 4rem;
                box-shadow: 0px 20px 60px #dbdada;
                span {
                    font-size: 1.3rem;
                    padding: 1rem;
                    font-weight: 500;
                }
                .hover-text {
                    z-index: 999;
                    background: $secondary-color-3 !important;
                    transition: all 0.4s ease-in;
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0;
                    span {
                        letter-spacing: 1px;
                        font-weight: 500;
                        font-size: 1rem !important;
                    }
                }
                &:hover .hover-text {
                    opacity: 1;
                }
            }
        }
        .primer-bloque-info {
            margin-top: 3rem;
            span {
                font-size: 1.35rem;
                font-weight: 700 !important;
            }
            i {
                font-size: 1.3rem;
                color: #0c446f;
                margin-right: 1rem;
            }
            p {
                font-size: 1.35rem;
                display: flex;
                line-height: 1;
                margin: 0;
                margin-bottom: .6rem;
                a {
                    font-size: 1.35rem;
                    font-weight: 400;
                    color: $secondary-color-1;
                }
            }
        }
    }
    .div-bloque-dos {
        .footer-social-media {
            .content-icon {
                width: 4rem;
                height: 4rem;
                position: relative;
                border: solid thin $secondary-color-1;
                border-radius: 15px;
                text-align: center;
                line-height: 5rem;
                margin-right: 2rem;
                margin-top: 4rem;
                box-shadow: 0px 20px 60px #dbdada;
                &:hover {
                    border-color: $background-3;
                    a {
                        color: $background-3;
                        &.icon-facebook {
                            color: transparent;
                            -webkit-text-stroke: 2px $background-3;
                        }
                    }
                }
                a {
                    font-size: 2rem;
                    color: $secondary-color-1;
                    font-weight: 200;
                    &.icon-facebook {
                        color: transparent;
                        -webkit-text-stroke: 2px $secondary-color-1;
                    }
                }
            }
        }
        .segundo-bloque-info {
            margin-top: 3rem;
            span {
                font-size: 1.35rem;
                font-weight: 700 !important;
            }
            i {
                font-size: 1.3rem;
                color: #0c446f;
                margin-right: 1rem;
            }
            p {
                font-size: 1.35rem;
                display: flex;
                margin: 0;
                line-height: 1;
                margin-bottom: .6rem;
                a {
                    font-size: 1.35rem;
                    font-weight: 400;
                    color: $secondary-color-1;
                }
            }
        }
    }
    .content-newsletter {
        position: relative;
        & form {
            padding: 20px 30px;
            border-radius: 30px;
            box-shadow: 0px 20px 60px #dbdada;
            & label img {
                width: 15px;
            }
            & label {
                font-weight: 300;
            }
        }
        span.subscribe-text {
            font-size: 1.4rem;
            font-weight: 500;
        }
        input[type="text"] {
            width: 100%;
            padding: 0 2rem;
            border: none;
            background-color: transparent;
            &::placeholder {
                color: $tertiary-color-1;
                font-size: 20px;
            }
        }
        button {
            position: absolute;
            right: 1.25rem;
            height: 3rem;
            width: max-content;
            padding: 0;
            border: none;
            top: 50%;
            transform: translateY(-50%);
            padding: 1rem;
            border-radius: 15px;
            display: block;
            line-height: 1;
            z-index: 99;
            transition: 0.3s ease all;
            background-color: $background-3;
            color: #fff;
            &:hover {
                background-color: $background-1;
                border: 1px solid $background-3;
                color: $background-3;
            }
            &:disabled {
                cursor: not-allowed;
            }
            // i {
            //     font-size: 1.5rem;
            //     line-height: 1;
            //     color: $secondary-color;
            // }
            @media (max-width: $breakpoint-tablet) {
                position: static;
                bottom: 0;
                left: 0;
                margin: 1.5rem -1rem -1rem -1rem;
                // margin-bottom: 0;
                width: calc(100% + 2rem);
            }
        }
        @media (min-width: $bt-bp-md + 1px) {
            height: 112px;
            & form {
                height: 100%;
                button.btn-subscribe {
                    font-size: 30px;
                    padding: 10px 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 23px;
                    box-sizing: content-box;
                }
            }
        }
        @media (min-width: $bt-bp-lg) and (max-width: $bt-bp-xl) {
            height: 92px;
            & form {
                padding: 15px 30px;
                button.btn-subscribe {
                    font-size: 22px;
                    padding: 5px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 18px;
                    box-sizing: content-box;
                }
            }
        }
    }
    .copy {
        font-size: 1.3rem;
        color: $secondary-color-1;
        font-weight: 300;
        .no-link {
            font-weight: 400;
            color: $secondary-color-1 !important;
        }
        a {
            color: #00a1ff !important;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .footer-captcha {
        margin-left: 3.5rem;
        margin-bottom: 6rem;
    }
    .footer-link {
        color: $secondary-color-1;
        &:hover {
            color: $primary-color;
        }
    }
    header {
        position: relative;
        .container-fluid {
            max-width: 100% !important;
            padding: 0;
            nav {
                a {
                    @media (max-width: $breakpoint-laptop) {
                        margin: 0 auto;
                    }
                }
                .mobile-nav {
                    display: none !important;
                }
                .list-wrap {
                    display: none !important;
                    @media (min-width: 1140px) {
                        display: inline-flex !important;
                    }
                }
                &::after {
                    content: none;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-laptop) {
    .footer-captcha {
        margin-left: 0px !important;
    }
    .footer-home {
        header {
            img {
                width: 13rem;
                height: 9rem;
            }
        }
    }
    footer {
        padding-bottom: 4rem;
        border-radius: 0px;
        // .go-top {
        //   display: none !important;
        // }
        &::before {
            display: none;
        }
        ul {
            li {
                display: block;
                margin-bottom: 1rem;
                a {
                    font-size: 1.3rem;
                    i {
                        display: none;
                    }
                }
            }
        }
        .line-degrade {
            margin-top: 3rem;
        }
        .data-footer {
            margin-top: 3rem;
            margin-bottom: 1rem;
            text-align: center;
            h4 {
                margin-right: 0;
                max-width: 100%;
                min-width: 100%;
                text-align: center;
                min-width: 30vw;
                display: block;
            }
            a.btn-square {
                margin: 0 0.5rem;
            }
            .data-single {
                text-align: center;
                margin: auto;
                width: 100%;
                h5,
                .mailto {
                    font-size: 0.9rem;
                    text-align: center;
                }
            }
        }
        .content-newsletter {
            margin-bottom: 2rem;
            margin-top: 5rem;
            input[type="text"] {
                padding: 1rem 1.9rem;
                font-size: 0.8rem;
            }
            .btn-square {
                width: 4rem;
                height: 4rem;
                right: 1rem;
                top: -5px;
            }
            span.subscribe-text {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
        .copy {
            font-size: 1rem;
            justify-content: center;
            text-align: center;
            margin: auto;
            width: 100%;
            margin-top: 0;
        }
        .div-bloque-uno,
        .div-bloque-dos {
            padding: 1rem;
            .footer-login-button {
                margin: auto !important;
                margin-top: 4rem !important;
            }
            .footer-social-media {
                .content-icon {
                    margin: 1.5rem 0.5rem 0.5rem 0.5rem;
                }
            }
            .primer-bloque-info,
            .segundo-bloque-info {
                margin-top: 5rem;
                display: block;
                text-align: center;
                i {
                    font-size: 1.5rem;
                    display: block;
                    margin-top: 2rem;
                    margin-right: 0;
                }
                .icon-location1 {
                    font-size: 1.7rem;
                }
                p {
                    display: block;
                    font-size: 1.2rem;
                    margin: .5rem auto 1rem auto;
                    text-align: center;
                    width: 100%;
                }
                a {
                    font-size: 1.2rem !important;
                    text-align: center;
                }
            }
            .segundo-bloque-info {
                margin-top: 2rem;
            }
        }
    }
}