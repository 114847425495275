.Emprendimiento {
  .lotes {
    padding: 10vh 0;
    padding-bottom: 3rem;
    margin-bottom: 0rem;

    .table-wrapper{
        overflow: auto;
    }
    table {
      width: 80%;
      margin: auto;

      tr {
        height: 50px;
      }
      td {
        color: #686881;
        font-weight: 500;
        height: 100%;
        padding: 0 15px;

        .btn-medium-dark {
          padding: 8px 10px;
          margin: 20px 0;
          display: flex;
          align-items: center;
        }
        i {
          font-size: 25px;
        }
      }
    }
    .underlined {
      border-bottom: 1px solid #070707;
    }
  }
}

@media (max-width: $breakpoint-laptop) {
  .Emprendimiento {
    .lotes {
      padding-top: 2rem;
      h2 {
        margin-bottom: 2.2rem;
      }

      table {
        width: max-content;
      }
    }
  }
}
