.info-util {
    background-color: $background-1;
    padding: 15rem 0 13rem 0;
    h1.title-1 {
        font-size: 4rem;
        line-height: 4.5rem!important;
    }
    .last-col {
        @media (min-width: $bt-bp-md) and (max-width: $bt-bp-lg + 1px) {
            width: 100%;
            gap: 1.6rem;
            display: flex;
            justify-content: space-between;
            div {
                width: 100%;
            }
        }
    }
}