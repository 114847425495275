.emprendimientos {
    .investors {
        background-color: $background-1;
        .container-fluid { 
            overflow: hidden;
            padding-top: 2rem;
            padding-bottom: 0vh;
            position: relative;
            h4 {
                text-align: center;
                padding-bottom: 10vh;
            }
            .items-wrapper{
                display: grid;
                grid-template-columns: 1fr 1fr;
                max-width: 1100px;
                margin: auto;

                & > div:nth-child(2n-1){
                    max-width: 400px;
                    align-self: flex-end;
                    justify-self: center;
                    & > div{
                        margin-bottom: 10rem;
                    }
                }
                & > div:nth-child(2n){
                    position: relative;
                    & > div{
                        position: absolute;
                        top: 20rem;
                        left: 50%;
                        transform: translateX(-50%);
                        max-width: 400px;
                        width: 100%;
                    }
                }
            }
            .item {
                height: auto;
                overflow: hidden;
                position: relative;
                transition: .4s ease all;
                z-index: 3;
                background-color: #fff;
                padding: 3rem;
                border-radius: 30px;
                // min-height: 430px;
                display: flex;
                flex-direction: column;

                & > div{
                    flex:1;
                    text-align: left;
                }

                h5 {
                    transition: .4s ease all;
                    color: #000;
                    font-size: 2rem;
                    text-align: left;
                }
                p {
                    font-size: 1.5rem;
                    text-align: left;
                    line-height: 1.2;
                    max-width: 90%;
                    color: #000;
                    transition: .4s ease all;
                }
                i{
                    color: $primary-color-1;
                    font-size: 40px;
                    font-weight: 500;
                }
                
                .contact-link{
                    text-align: left;
                }
                // &:hover {
                //     transform: translateY(-4rem);
                //     .btn-square {
                //         transform: translateY(0);
                //     }
                //     img {
                //         // position: absolute;
                //         // top: -500px;
                //         opacity: 0;
                //     }
                //     h5 {
                //         color: $primary-color;
                //     }
                // }
            }
        }
    }
}

@media (max-width:992px) {
    .emprendimientos {
        .investors {
            .container-fluid {
                // padding-top: 10vh;
                padding-bottom: 5vh;
                .index-1 {
                    width: 100%;
                    .line-vertical {
                        opacity: .0;
                    }
                }
                h4 {
                    padding-bottom: 5vh;
                }
                .items-wrapper{
                    grid-template-columns: 1fr;
    
                    & > div:nth-child(2n-1){
                        align-self: flex-end;
                        justify-self: center;
                        & > div{
                            margin-bottom: 2rem;
                        }
                    }
                    & > div:nth-child(2n){
                        position: static;
                        max-width: 400px;
                        margin: auto;
                        margin-bottom: 2rem;
                        & > div{
                            position: static;
                            transform: none;
                        }
                    }
                }

                .item {
                    padding: 2rem;
                    h5 {
                        // min-height: 6.5rem;
                    }
                    h5,
                    p {
                        font-size: 1.3rem;
                        width: 85%;
                        line-height: 1.25;
                    }
                    .btn-square {
                        padding: .6rem 6rem;
                        margin: 1.5rem 0 5rem 0;
                        transform: translateY(0rem);
                    }
                    &:hover {
                        transform: translateY(0rem);
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}