.favoritos {
    .main {
        padding: 15rem 0 13rem 0;
        background-color: $background-1;
        h4 {
            color: $background-3 !important;
        }
        &.dark {
            background-color: $secondary-color;
            h2 {
                color: #fff;
            }
        }
    }
}

@media (max-width:992px) {
    .favoritos {
        .main {
            h2 {
                font-size: 3rem;
                margin-bottom: 2rem;
            }
        }
    }
}