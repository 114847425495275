.title-1 {
    font-size: 4rem;
    font-weight: 500;
    line-height: 6rem !important;
}

.title-2 {
    font-weight: 300;
    font-size: 30px;
}

.paragraph-1 {
    font-size: 1.25rem;
    font-weight: 500;
}

a:hover {
    color: #fff;
}

@media (max-width: $breakpoint-tablet) {
    .title-1 {
        font-size: 2.5rem;
        line-height: 40px !important;
    }
}