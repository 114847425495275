header {
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 999;
    background: $background-1;
    border-bottom: none;
    &.home-nav {
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 10px;
            left: 0;
            right: 0;
            bottom: -10px;
            // z-index: 9999;
            background-color: #6e95c0;
        }
    }
    nav {
        width: 100%;
        // height: 9.3rem;
        // padding-top: 3rem;
        a {
            img {
                // width: 15rem;
                height: 9rem;
            }
        }
        .logo {
            width: 10vw;
        }
        .favorite-button {
            background-color: transparent;
            color: $secondary-color-1;
            border: solid thin $secondary-color-1;
            border-radius: 13px;
            padding: 10px 20px;
            transition: background-color 0.25s, color 0.25s;
            font-weight: 500;
            &:hover {
                background-color: $secondary-color-1;
                color: #fff !important;
            }
        }
        ul.list-wrap {
            display: inline-flex;
        }
        ul {
            padding-top: 1rem;
            & .hover-ball {
                width: max-content;
            }
            & .hover-ball::after {
                content: "";
                position: absolute;
                opacity: 0;
                top: 10px;
                left: 50%;
                border-radius: 50%;
                transform: translateX(-50%);
                width: 6px;
                height: 6px;
                background-color: $background-3;
                transition: top 0.5s ease 0s, opacity 0.3s;
            }
            &.hover-ball:hover::after {
                opacity: 1;
                top: 85%;
            }
            li {
                margin-left: 2.5vw;
                list-style: none;
                position: relative;
                a {
                    text-decoration: none;
                    font-size: 1.5rem;
                    color: $secondary-color-2;
                    transition: 0.2s ease all;
                    font-weight: 400;
                    align-self: center;
                    &:hover {
                        color: $background-3 !important;
                    }
                }
                & .hover-ball::after,
                &.active::after {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    top: 10px;
                    left: 50%;
                    border-radius: 50%;
                    transform: translateX(-50%);
                    width: 6px;
                    height: 6px;
                    background-color: $background-3;
                    transition: top 0.5s ease 0s, opacity 0.3s;
                }
                &.hover-ball:hover::after {
                    opacity: 1;
                    top: 85%;
                }
                &.active {
                    position: relative;
                    width: max-content;
                    a {
                        color: $background-3 !important;
                        position: relative;
                    }
                    &::after {
                        opacity: 1;
                        top: 85%;
                    }
                }
                // & .facebook-icon {
                //   background: url("../../images/icons/facebook-icon.svg");
                //   &:hover {
                //     background: url("../../images/icons/facebook-orange-icon.svg");
                //     background-size: 8px;
                //   }
                // }
                // & .instagram-icon {
                //   background: url("../../images/icons/instagram-icon.svg");
                //   &:hover {
                //     background: url("../../images/icons/instagram-orange.svg");
                //     background-size: 12px;
                //   }
                // }
                // & .twitter-icon {
                //   background: url("../../images/icons/twitter-icon.svg");
                //   &:hover {
                //     background: url("../../images/icons/twitter-orange-icon.svg");
                //     background-size: 15px;
                //   }
                // }
                &.iconos-redes {
                    a {
                        .icon-facebook1,
                        .icon-instagram1,
                        .icon-twitter {
                            height: 25px;
                            width: 25px;
                            background-repeat: no-repeat !important;
                            background-position: center !important;
                            border-radius: 5px;
                            border: solid thin $background-3;
                            background-color: $background-3;
                            color: white;
                            font-size: 1rem;
                            text-align: center;
                            line-height: 25px;
                            &:hover {
                                background-color: $secondary-color-3;
                                border: none;
                            }
                            &::before {
                                align-self: center;
                            }
                        }
                    }
                }
            }
            .social-header {
                line-height: 1;
                .icons {
                    margin-right: 1rem;
                    height: 1.5rem;
                    align-items: center;
                    justify-content: center;
                    vertical-align: middle;
                    width: 1.5rem;
                    background: $tertiary-color;
                    border-radius: 5px;
                    display: inline-flex;
                    transition: 0.3s ease all;
                    a {
                        text-decoration: none;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                    }
                    i {
                        transition: 0.3s ease all;
                        color: #fff;
                        // line-height: 1.2;
                        font-size: 0.7rem;
                    }
                    &.fav-icon {
                        background: transparent;
                        // margin-left: 0 !important;
                        i {
                            color: rgba($primary-color, 1);
                            -webkit-text-stroke: 2px rgba($secondary-color, 1);
                            font-size: 1.2rem;
                            &.notActived {
                                color: transparent;
                                -webkit-text-stroke: 2px rgba($secondary-color, 1);
                                border-radius: 5px;
                                text-decoration: none;
                            }
                        }
                        &:hover {
                            background-color: transparent !important;
                            i {
                                color: $primary-color !important;
                                -webkit-text-stroke: 2px rgba($primary-color, 1);
                            }
                        }
                    }
                    &:hover {
                        background: #fff !important;
                        i {
                            color: $primary-color !important;
                        }
                    }
                    &:nth-child(1) {
                        margin-left: 4vw;
                    }
                }
            }
        }
    }
    &.results-nav {
        position: fixed;
        // padding-bottom: 1rem;
        nav {
            // height: 9rem;
            ul {
                li a {
                    color: #000;
                }
            }
            .social-header .icons.fav-icon i {
                -webkit-text-stroke: 1px #000 !important;
            }
        }
        .search-results {
            padding: 0rem 5% 2rem 5%;
            form {
                label {
                    color: #fff;
                }
                .content-fields {
                    border-color: #fff;
                    select {
                        color: #fff;
                        option {
                            color: rgba($secondary-color, 0.97);
                        }
                    }
                    input[type="text"] {
                        color: #fff;
                        &::placeholder {
                            color: #fff;
                        }
                    }
                    i {
                        color: #fff;
                    }
                }
            }
            .btn-square {
                border: 1px solid rgba($primary-color, 1);
                &:hover {
                    border: 1px solid #fff !important;
                }
            }
        }
        &.dark {
            background: linear-gradient(135deg, #fff 93%, transparent 6%);
            ul {
                li {
                    a {
                        color: rgba($secondary-color, 1);
                    }
                }
                .social-header .icons.fav-icon i {
                    -webkit-text-stroke: 2px rgba($secondary-color, 1) !important;
                }
            }
        }
    }
    &.home-nav {
        nav ul li a {
            // color: $secondary-color;
        }
    }
    &.dev-nav {
        nav ul {
            li a {
                color: #fff;
            }
            .social-header .icons.fav-icon i {
                -webkit-text-stroke: 1px #fff;
            }
        }
    }
    &.white-cut-nav {
        // background: linear-gradient(135deg, #fff 93%, transparent 6%);
        ul {
            li {
                a {
                    color: $secondary-color;
                }
            }
        }
    }
}

@media (max-width:1300px) {
    header {
        nav {
            a {
                img {
                    height: 7rem;
                }
            }
            ul {
                li {
                    a {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-laptop) {
    header {
        display: flex;
        align-items: center;
        background: $background-1;
        height: auto;
        z-index: 9999;
        border-bottom: solid 2px $background-3;
        &.contact-nav {
            padding-bottom: 0;
            background-color: rgba(#000, 0.4);
        }
        &.results-nav {
            background: $background-1 !important;
            height: 4rem;
            padding-bottom: 0;
            nav {
                height: unset;
            }
            .fav-icon {
                a {
                    i.notActived {
                        -webkit-text-stroke: 2px rgba($background-3, 1);
                    }
                }
            }
            .menu-toggle i {
                background-color: $background-3;
            }
            .search-results {
                display: none;
            }
        }
        nav {
            padding-top: 0rem;
            height: unset;
            z-index: 99999;
            a {
                img {
                    height: 5.5rem;
                }
            }
            .mobile-nav {
                display: flex !important;
                align-items: center;
                justify-content: flex-end;
            }
            .menu-toggle {
                display: block !important;
                background-color: transparent;
                position: relative;
                z-index: 99999;
                border: none;
                width: unset;
                cursor: pointer;
                display: block;
                transition: 0.4s ease-in all !important;
                &:focus {
                    outline: none;
                }
                i {
                    display: block;
                    background-color: $background-3;
                    height: 2px;
                    margin-bottom: 0.45rem;
                    transition: 0.4s ease-in-out all !important;
                    &:nth-child(1) {
                        width: 1.7rem;
                    }
                    &:nth-child(2) {
                        width: 1.7rem;
                    }
                    &:nth-child(3) {
                        width: 1.7rem;
                        margin-bottom: 0;
                    }
                }
                &.open {
                    i {
                        &:nth-child(1) {
                            transform: rotate(-45deg) translate(-0.37rem, 0rem);
                            width: 1.7rem;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                            display: none;
                        }
                        &:nth-child(3) {
                            width: 1.7rem;
                            transform: rotate(225deg) translate(0.4rem, 0.04rem);
                        }
                    }
                }
            }
            .fav-icon {
                position: relative;
                z-index: 999999;
                margin-top: 2px;
                line-height: 1;
                a {
                    color: $primary-color;
                    text-decoration: none;
                    font-size: 1.3rem;
                    i.notActived {
                        color: transparent;
                        -webkit-text-stroke: 1px rgba($background-3, 1);
                        border-radius: 5px;
                        text-decoration: none;
                    }
                }
            }
            .logo {
                width: 30vw;
                position: relative;
                z-index: 999999;
            }
            ul.list-wrap {
                display: block;
            }
            ul {
                position: fixed;
                left: 200%;
                background-color: rgba($background-1, 0.97);
                bottom: 0;
                padding: 1rem 1.5rem;
                top: 13vh;
                overflow: scroll;
                height: 100vh;
                transition: 0.4s ease all;
                width: 100vw;
                right: 0;
                padding-top: 3rem;
                text-align: -webkit-center;
                &.open {
                    left: 0;
                }
                li {
                    margin-bottom: 1.5vh;
                    // color: #000;
                    a {
                        // color: rgba(#000, 1) !important;
                        font-size: 8vw;
                        letter-spacing: 0.5px;
                        font-weight: 500;
                    }
                    // & .hover-ball::after,
                    // &.active::after {
                    //   left: -3px;
                    // }
                    // &.hover-ball:hover::after {
                    //   opacity: 1;
                    //   left: -3px !important;
                    // }
                    &.hover-ball::after {
                        left: calc(100% + 10px);
                        top: 100%;
                    }
                    &.hover-ball:hover::after,
                    &.active::after {
                        opacity: 1;
                        left: calc(100% + 10px) !important;
                        top: 50% !important;
                    }
                    &.iconos-redes {
                        margin-top: 3rem;
                        justify-content: center;
                        a {
                            .icon-facebook1,
                            .icon-instagram1,
                            .icon-twitter {
                                height: 45px;
                                width: 45px;
                                line-height: 45px;
                                font-size: 2rem;
                                margin: 0rem .5rem;
                                &:hover {}
                                &::before {}
                            }
                        }
                    }
                }
                .social-header {
                    position: absolute;
                    // bottom: 15vh;
                    padding-top: 7rem;
                    .icons {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
    }
}

@media (min-width: $bt-bp-lg) and (max-width: $breakpoint-laptop) {
    header {
        nav {
            .mobile-nav {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}