$primary-color: #dc202d;
$primary-color-1: #f37535;
$primary-color-2: #d4752b;
$secondary-color: #1d1d1d;
$secondary-color-2: #005898;
$secondary-color-1: #005e9b;
$secondary-color-3: #0c446f;
$tertiary-color: linear-gradient( 0deg, rgba(249, 127, 45, 1) 0%, rgba(204, 145, 42, 1) 100%);
$tertiary-color-1: #6e95c0;
$tertiary-color-horizontal: linear-gradient( 90deg, rgba(249, 127, 45, 1) 0%, rgba(204, 145, 42, 1) 100%);
$border-color-1: #9999af;
$background-1: #f3edec;
$background-2: #fefcf9;
$background-3: #dc202d;
$green-wp: #60a72e;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1366px;
// Bootstrap Breakpoints
$bt-bp-sm: 576px;
$bt-bp-md: 768px;
$bt-bp-lg: 992px;
$bt-bp-xl: 1200px;
$bt-bp-xxl: 1400px;