.Resultados {
    padding-bottom: 10vh;
    // min-height: 150vh;
    background-color: $background-1;
    .select-wrapper {
        position: relative;
        i {
            position: absolute;
            right: -8px;
            top: -1px;
            font-size: 2.3rem;
            color: $background-3;
            transform: rotate(90deg);
            transition: transform 0.3s;
        }
        &:focus-within {
            i {
                transform: rotate(-90deg);
            }
        }
        #resizing_select {
            text-align: left !important;
            width: 8rem;
            z-index: 111;
            cursor: pointer;
            background-color: transparent;
            min-width: 8rem !important;
            all: unset;
            font-weight: 500;
            padding-left: 7px;
            position: relative;
            // background-color: blue;
            &::after {
                content: "";
                width: 10px;
                height: 10px;
                position: absolute;
                right: 0;
                top: 0;
                background-color: red;
            }
        }
    }
    #width_tmp_select {
        display: none;
    }
    .main {
        padding-top: 15rem;
        // padding-top: 25rem;
        padding-bottom: 5rem;
        h5 {
            font-size: 1.7rem;
            color: $secondary-color;
            font-weight: 400;
        }
        h2 {
            // font-weight: 300;
            font-size: 6rem;
            color: $secondary-color;
            span {
                color: $background-3;
                font-size: 6rem;
                text-transform: uppercase;
            }
        }
        .btn-square {
            width: 6rem;
            height: 4.5rem;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem;
            border: 1px solid $primary-color;
            background: transparent;
            color: $primary-color;
            &.active {
                border: transparent;
                background: $background-3;
                i {
                    color: #fff;
                }
            }
        }
        .listing-type {
            display: flex;
            justify-content: center;
            gap: 8px;
            i {
                color: $background-3;
                padding-top: 4px;
                &:hover {
                    cursor: pointer;
                    color: $background-3;
                }
            }
            .active {
                color: $background-3;
            }
            div {
                font-weight: 500;
                font-size: 1.3rem;
            }
        }
        .orderby {
            font-size: 1.3rem;
        }
        .orderbys {
            font-size: 1rem;
            color: $secondary-color;
            width: 100%;
            line-height: 1;
            // font-weight: 200;
            // text-transform: uppercase;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            button {
                border: none;
                background-color: transparent;
                font-weight: 200;
                i {
                    color: $primary-color;
                }
            }
            select {
                border: none;
                line-height: 1;
                // height: 1rem;
                // font-size: 1rem;
                background-color: transparent;
                font-weight: 500;
                color: $secondary-color;
            }
        }
        .not-results-message {
            color: $secondary-color-1;
            font-weight: 500;
            display: block;
            text-transform: none;
            margin-top: 2rem;
            font-size: 1.5rem;
        }
    }
    &.dark {
        background-color: $secondary-color;
        .breadcrumb,
        .breadcrumb-item {
            color: #fff;
            &::before {
                color: #fff;
            }
        }
        .main {
            h2,
            h5 {
                color: #fff;
            }
            .btn-square {
                background-color: transparent !important;
                border: 1px solid $primary-color !important;
                i {
                    color: $primary-color;
                }
            }
            .not-results-message {
                color: #fff;
            }
        }
    }
    .results {
        padding: 0 0 0 0%;
    }
}

@media (max-width: 992px) {
    .Resultados {
        .main {
            padding-top: 17rem;
            padding-bottom: 4rem;
            button {
                background-color: $background-3;
                color: #fff;
                border: none;
                padding: 1rem;
                position: fixed;
                top: 7.6rem;
                left: 0;
                right: 0;
                z-index: 1000;
                width: 100%;
                transition: 0.4s ease all;
                &.filters-top {
                    position: fixed;
                    top: 0;
                }
            }
            h5 {
                font-size: 0.8rem;
                margin-top: 1.5rem;
            }
            h2 {
                font-size: 1.8rem;
                line-height: 1.3;
                display: inline-block;
                margin-bottom: 0;
                span {
                    display: inline-block;
                    margin-bottom: 0;
                    font-size: 1.8rem;
                }
            }
            span {
                font-size: 0.7rem;
            }
        }
        .results {
            padding: 0;
        }
    }
}