.propiedad, .Emprendimiento {
    
}

@media (max-width:992px) {
    .propiedad {
        .main {
            height: 90vh;
            position: relative;
            min-height: 90vh;
            &::before {
                opacity: .6;
            }

            .grid-property-images{
                grid-template-columns: 1fr;

                .open-images{
                    margin: 1rem;
                    font-size: 12px;
                    padding: 6px 14px;
                }

                .right-side{
                    display: none;
                }
            }
        }
    }
}