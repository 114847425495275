* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    font-size: 14px;
}

body {
    font-size: 14px;
    font-family: "TT Commons";
    font-weight: 100;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    font-weight: 300;
}

.section_indicator {
    border-bottom: 2px solid $secondary-color;
    padding-bottom: 0.9rem;
    margin-bottom: 4rem;
    span {
        width: 100%;
        font-size: 0.68rem;
        font-weight: 300;
    }
}

.owl-nav.disabled {
    // display: none !important;
}

.line-vertical {
    // height: 100vh;
    // width: 100%;
    border-left: 1px solid #fff;
    display: block;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
}

span {
    font-weight: 400;
}

p,
li {
    font-weight: 400;
}

.border-none {
    border: none;
}

#text-form {
    color: #fff;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    position: relative;
    &::before {
        z-index: -1;
        border-radius: 5px;
        background-color: $primary-color;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .4;
    }
}

input:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.see_description {
    font-size: 1.4rem;
    line-height: 1;
    margin-left: 1rem;
    font-weight: 600;
    color: $primary-color;
    &:hover {
        color: $primary-color;
    }
}

.social-media {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    .single {
        width: 3.2rem;
        height: 3.2rem;
        display: flex;
        transition: 0.4s ease all;
        align-items: center;
        justify-content: center;
        // background-color: $secondary-color;
        cursor: pointer;
        margin-right: 2rem;
        border-bottom: none !important;
        text-decoration: none;
        .small-single {
            width: 0.9rem;
        }
        img {
            width: 1.5rem;
        }
        i {
            font-size: 1.4rem;
            color: $primary-color;
        }
        &:hover {
            i {
                color: $primary-color;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.fixed-bar {
    bottom: 10vh;
    right: 5%;
    z-index: 5;
    opacity: 1;
    // display: flex;
    transition: 0.4s ease all;
    &.opacity {
        opacity: 0;
    }
    .btn {
        padding: 0;
        position: relative;
        z-index: 98;
        width: 5.5rem;
        height: 5.5rem;
        font-size: 1.5rem;
        color: transparent;
        background: $secondary-color;
        border: 2px solid #fff;
        margin-left: 1rem;
        transition: 0.2s ease all;
        &:hover {
            background: $tertiary-color;
            border: 2px solid $primary-color;
            span {
                color: #fff !important;
            }
        }
        &.disabled {
            opacity: 0.3 !important;
            cursor: not-allowed !important;
            pointer-events: unset !important;
        }
    }
    &.opacity-total {
        opacity: 0;
    }
    .contact-btn {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $secondary-color-1;
        color: #fff;
        font-size: 2.5rem;
        cursor: pointer;
        position: relative;
        z-index: 9;
        &.branch-btn {
            display: flex;
            text-align: center;
            font-size: 1rem;
            z-index: 1;
            margin-bottom: 1rem;
            color: #fff;
            cursor: pointer;
            position: absolute;
            opacity: 0;
            transition: .4s ease;
            &.open {
                position: relative;
                opacity: 1;
            }
            &:after {
                background: transparent;
                border: 3px solid $primary-color;
            }
        }
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: $primary-color;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0);
            will-change: transform;
            transition: 0.4s;
            border-radius: 50%;
        }
        &:hover:after {
            transform: scale(1);
            opacity: 1;
        }
        &:before {
            z-index: 9;
            transition: 0.3s;
            transition-delay: 0.1s;
        }
        &.bg-green {
            background-color: $green-wp;
        }
    }
    .pulse-border {
        left: 0;
        right: 0;
        bottom: 0;
        width: 5rem;
        height: 5rem;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        animation-play-state: paused;
    }
    .pulse-border::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        opacity: 0.75;
        position: absolute;
        border-radius: 50%;
        animation-duration: 1.5s;
        border: 5px solid $green-wp;
        animation-name: pulse-border;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
    }
}

// Pulse animation
@keyframes pulse-border {
    0% {
        transform: scale(1.15);
        opacity: 0.75;
    }
    75% {
        transform: scale(1.30);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.btn {
    position: relative;
    display: flex !important;
    align-items: center;
    background: transparent;
    width: fit-content;
    color: $primary-color;
    height: 3.2rem;
    padding: 0 2.5rem;
    padding-left: calc(2.5rem + 3rem);
    font-size: 1rem;
    letter-spacing: -0.1px;
    border-radius: 0;
    transition: 0.3s ease all;
    text-decoration: underline;
    &::before {
        font-family: "icomoon" !important;
        content: "\e90d";
        font-size: 0.9rem;
        line-height: 1;
        display: flex;
        padding-bottom: 3px;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        width: 3.2rem;
        height: 3.2rem;
        top: -4px;
        z-index: 1;
        bottom: 0;
        text-transform: uppercase;
        background: $tertiary-color;
        border-radius: 50%;
        color: #0d0d0d;
        transition: all 0.5s ease;
    }
    &:hover {
        letter-spacing: 0.1px;
        color: $primary-color;
        &::before {
            transform: translateX(1rem);
        }
    }
    &.transparent {
        background: transparent;
    }
    &.black {
        color: #0d0d0d;
        &::before {
            background: #0d0d0d !important;
            color: #fff;
        }
    }
}

.btn-square {
    padding: 1.2rem 2rem;
    background: $tertiary-color;
    color: #fff;
    z-index: 90;
    transition: 0.3s ease all;
    cursor: pointer;
    &.dark-hover {
        &:hover {
            background: $secondary-color;
        }
    }
    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.btn-rounded {
    width: max-content;
    border: none;
    padding: 1.25rem 3rem;
    border-radius: 15px;
    line-height: 1;
    z-index: 99;
    transition: 0.3s ease all;
    background-color: $background-3;
    color: #fff;
    font-size: 2rem;
    &:hover {
        background-color: $secondary-color-1;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }
    @media (max-width: $breakpoint-tablet) {
        // padding: .75rem 1.25rem;
        // font-size: 18px;
    }
}

.btn-medium,
.btn-medium-dark,
.btn-medium-1 {
    background-color: transparent;
    color: $secondary-color-1;
    border: solid thin $secondary-color-1;
    border-radius: 13px;
    padding: 10px 20px;
    transition: background-color 0.25s, color 0.25s;
    font-weight: 500;
    &:hover {
        background-color: $secondary-color-1;
        color: #fff;
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        border: solid thin $secondary-color-1;
    }
}

.btn-medium-1 {
    border-radius: 10px;
    &:hover {
        background-color: $primary-color-1;
        border: solid thin $primary-color-1;
    }
}

.btn-medium-dark {
    background-color: $secondary-color-1;
    color: #fff;
    &:hover {
        color: $secondary-color-1;
        background-color: #fff;
    }
}

.social-block {
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    z-index: 1;
    &.hidden {
        transition: all 0.3s ease;
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        right: 0;
        opacity: 0;
        z-index: -1;
        position: absolute;
    }
    .btn-square {
        width: 2.5rem !important;
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem !important;
        i {
            margin: 0 !important;
        }
        &:hover {
            border: none;
        }
    }
}

.index-1 {
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 40%;
    .line-vertical {
        opacity: 0.25;
        width: 1px;
        height: 100%;
    }
}

.h-100 {
    height: 100% !important;
}

.w-auto {
    // width: auto !important;
}

.text-primary-color {
    color: $primary-color;
}

.map {
    height: 60vh;
}

@media (max-width: 992px) {
    .section_indicator {
        border-bottom: 1px solid $secondary-color;
        padding-bottom: 5px;
        margin-bottom: 2rem;
        span {
            font-size: 0.4rem;
        }
    }
    .overflow-hidden-mobile {
        overflow: hidden;
    }
    .social-media {
        width: 100%;
        text-decoration: none;
        .single {
            width: 1.6rem;
            margin-right: 0.5rem;
            height: 1.6rem;
            .small-single {
                width: 0.6rem;
            }
            img {
                width: 0.8rem;
            }
        }
    }
    .btn {
        height: 3.2rem;
        padding: 0 2rem;
        padding-left: calc(2rem + 3rem);
        &::before {
            width: 3.2rem;
            height: 3.2rem;
        }
    }
    .fixed-bar {
        bottom: 5vh;
        right: 1rem;
        img {
            width: 4rem;
            height: 4rem;
            font-size: 1.5rem;
        }
        .btn.go-top.active {
            width: 4rem;
            height: 4rem;
        }
    }
    .map {
        height: 55vh;
        position: relative;
    }
    .w-80-sm {
        width: 80%;
    }
    .index-1 {
        width: 100%;
        .line-vertical {
            opacity: 0.1 !important;
        }
    }
    .see_description {
        font-size: 1rem;
        margin-left: 0rem;
        width: 100%;
        display: block;
        margin-top: 0.5rem;
        &::after {
            content: "...";
        }
    }
}

.logo-rotation {
    width: min-content;
    height: min-content;
    position: relative;
    & .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    & .text {
        animation: rotation_360 15s infinite linear;
    }
    @keyframes rotation_360 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.shadow-1 {
    box-shadow: 0px 60px 60px #eaddd9;
}

.shadow-2 {
    box-shadow: 0px 40px 60px #eaddd9;
}

.shadow-3 {
    box-shadow: 0px 40px 60px #eaddd96e;
}

.card-property {
    position: relative;
    // margin: 0 2rem;
    max-height: 400px;
    min-height: 350px;
    height: 100%;
    // max-width: 700px;
    margin: auto;
    transition: transform 0.5s, height 0.5s, width 0.5s;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    & .btn-rounded {
        position: absolute;
        top: 2rem;
        left: 2rem;
        opacity: 0;
        transition: opacity 0.3s;
        padding: 1rem 2.5rem;
    }
    &:hover .btn-rounded {
        opacity: 1;
    }
    & .lotes-slider-img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        box-shadow: 0px 50px 50px #d4752b23;
        &:hover {}
    }
    .item-description {
        position: absolute;
        bottom: -40%;
        left: -0.5px;
        opacity: 0;
        width: calc(100% + 1px);
        background-color: #ffffff77;
        backdrop-filter: blur(8px);
        border-radius: 0 0 30px 30px;
        transition: bottom 0.5s, opacity 0.6s;
        padding: 1rem 2.5rem;
        text-align: start;
        z-index: 10;
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & h4 {
            font-size: 25px;
            font-weight: 400;
        }
        & .description {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 18px;
            margin-bottom: 1rem;
            color: #000;
            i {
                // color: $border-color-1;
            }
            & img {
                width: auto;
            }
        }
        & .cost {
            font-size: 27px;
            font-weight: 600;
        }
        & .arrow-link {
            // position: absolute;
            // right: 2.5rem;
            // top: 50%;
            // transform: translateY(-50%);
            border-radius: 5px;
            border: solid thin #191919;
            padding: 15px 20px;
            &:hover {
                filter: invert(100%);
                background-color: white;
            }
            &>img {
                width: 13px;
            }
            @media (max-width: $breakpoint-tablet) {
                padding: 8px 15px;
                &>img {
                    width: 10px;
                }
            }
        }
    }
    &:hover .item-description {
        opacity: 1;
        bottom: 0;
        pointer-events: all;
    }
    @media (max-width: $breakpoint-tablet) {
        .item-description {
            opacity: 1;
            bottom: 0;
            backdrop-filter: blur(1px);
            padding: 15px;
        }
    }
}

.container-fluid {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

@media (min-width: 992px) {
    .container-fluid {
        max-width: 90%;
    }
}

@media (min-width: 992px) and (max-width: 1400px) {
    html {
        font-size: 12px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    html {
        font-size: 13px;
    }
}

@media (min-width: 1500px) and (max-width: 1920px) {
    html {
        font-size: 13px;
    }
}

@media (min-width: 1920px) {
    html {
        font-size: 15px;
    }
}

@media (min-width: 2000px) {
    html {
        font-size: 15px;
    }
}