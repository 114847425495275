.Emprendimiento {
    .main {
        padding-top: 10rem;
    }
}

@media (max-width:992px) {
    .Emprendimiento {
        .main {
            height: 90vh;
            background-attachment: unset;
            position: relative;
            min-height: 90vh;
            &::before {
                opacity: .6;
            }
            &::after {
                opacity: .3;
            }
            .content-main {
                height: 90vh;
                h2 {
                    font-size: 2.6rem;
                    display: block;
                    margin-bottom: 0 !important;
                    .location {
                        font-size: 1rem;
                    }
                }
                .btn-square {
                    width: 4rem;
                    height: 4rem;
                    position: absolute !important;
                    left: 1rem;
                    bottom: 5vh;
                    font-size: 1rem;
                }
            }
            .btn {
                width: 4rem;
                height: 4rem;
                position: absolute !important;
                left: calc(0%);
                bottom: 5vh;
                font-size: 1rem;
            }
        }
    }
}