@font-face {
    font-family: "LEMON MILK";
    src: url("../../fonts/LEMONMILK-Regular.eot");
    src: url("../../fonts/LEMONMILK-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/LEMONMILK-Regular.woff2") format("woff2"), url("../../fonts/LEMONMILK-Regular.woff") format("woff"), url("../../fonts/LEMONMILK-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LEMON MILK";
    src: url("../../fonts/LEMONMILK-Light.eot");
    src: url("../../fonts/LEMONMILK-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/LEMONMILK-Light.woff2") format("woff2"), url("../../fonts/LEMONMILK-Light.woff") format("woff"), url("../../fonts/LEMONMILK-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LEMON MILK";
    src: url("../../fonts/LEMONMILK-Medium.eot");
    src: url("../../fonts/LEMONMILK-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/LEMONMILK-Medium.woff2") format("woff2"), url("../../fonts/LEMONMILK-Medium.woff") format("woff"), url("../../fonts/LEMONMILK-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Commons";
    src: url("../../fonts/TTCommons-DemiBold.eot");
    src: url("../../fonts/TTCommons-DemiBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/TTCommons-DemiBold.woff2") format("woff2"), url("../../fonts/TTCommons-DemiBold.woff") format("woff"), url("../../fonts/TTCommons-DemiBold.ttf") format("../../fonts/Truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Commons";
    src: url("../../fonts/TTCommons-Light.eot");
    src: url("../../fonts/TTCommons-Light.eot?#iefix") format("embedded-opentype"), url("../../fonts/TTCommons-Light.woff2") format("woff2"), url("../../fonts/TTCommons-Light.woff") format("woff"), url("../../fonts/TTCommons-Light.ttf") format("../../fonts/Truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Commons";
    src: url("../../fonts/TTCommons-ExtraBold.eot");
    src: url("../../fonts/TTCommons-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../../fonts/TTCommons-ExtraBold.woff2") format("woff2"), url("../../fonts/TTCommons-ExtraBold.woff") format("woff"), url("../../fonts/TTCommons-ExtraBold.ttf") format("../../fonts/Truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Commons";
    src: url("../../fonts/TTCommons-Medium.eot");
    src: url("../../fonts/TTCommons-Medium.eot?#iefix") format("embedded-opentype"), url("../../fonts/TTCommons-Medium.woff2") format("woff2"), url("../../fonts/TTCommons-Medium.woff") format("woff"), url("../../fonts/TTCommons-Medium.ttf") format("../../fonts/Truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Commons";
    src: url("../../fonts/TTCommons-Regular.eot");
    src: url("../../fonts/TTCommons-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/TTCommons-Regular.woff2") format("woff2"), url("../../fonts/TTCommons-Regular.woff") format("woff"), url("../../fonts/TTCommons-Regular.ttf") format("../../fonts/Truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "icomoon";
    src: url("../../fonts/icomoon.eot?bwh58p");
    src: url("../../fonts/icomoon.eot?bwh58p#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?bwh58p") format("truetype"), url("../../fonts/icomoon.woff?bwh58p") format("woff"), url("../../fonts/icomoon.svg?bwh58p#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'icomoon';
    src: url('../../fonts/icomoon.eot?jbnuri');
    src: url('../../fonts/icomoon.eot?jbnuri#iefix') format('embedded-opentype'), url('../../fonts/icomoon.ttf?jbnuri') format('truetype'), url('../../fonts/icomoon.woff?jbnuri') format('woff'), url('../../fonts/icomoon.svg?jbnuri#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-orentation:before {
    content: "\e900";
}

.icon-aircond:before {
    content: "\e901";
}

.icon-garage:before {
    content: "\e902";
}

.icon-garden:before {
    content: "\e903";
}

.icon-pool:before {
    content: "\e904";
}

.icon-casa:before {
    content: "\e905";
}

.icon-fb-border:before {
    content: "\e908";
}

.icon-grid:before {
    content: "\e90a";
}

.icon-instagram:before {
    content: "\e90c";
}

.icon-celular:before {
    content: "\e90d";
}

.icon-list:before {
    content: "\e90e";
}

.icon-whatsapp1:before {
    content: "\e910";
}

.icon-location-map:before {
    content: "\e911";
}

.icon-pett:before {
    content: "\e914";
}

.icon-image:before {
    content: "\e918";
}

.icon-location-icon:before {
    content: "\e919";
}

.icon-quot:before {
    content: "\e91a";
}

.icon-house:before {
    content: "\e91b";
}

.icon-message:before {
    content: "\e91c";
}

.icon-key-icon:before {
    content: "\e91d";
}

.icon-arrow:before {
    content: "\e91e";
}

.icon-arrow-up:before {
    content: "\e91f";
}

.icon-dollar-arrow:before {
    content: "\e920";
}

.icon-surface-terreno:before {
    content: "\e921";
}

.icon-sun-theme:before {
    content: "\e922";
}

.icon-key:before {
    content: "\e923";
}

.icon-share:before {
    content: "\e924";
}

.icon-tick:before {
    content: "\e925";
}

.icon-tour-360:before {
    content: "\e926";
}

.icon-bed1:before {
    content: "\e927";
}

.icon-gym:before {
    content: "\e928";
}

.icon-twitter:before {
    content: "\e929";
}

.icon-instagram1:before {
    content: "\e92a";
}

.icon-facebook1:before {
    content: "\e92b";
}

.icon-home:before {
    content: "\e92c";
}

.icon-location1:before {
    content: "\e92d";
}

.icon-arrow-right:before {
    content: "\e92e";
}

.icon-location-solid:before {
    content: "\e92f";
}

.icon-like:before {
    content: "\e930";
}

.icon-bathtub:before {
    content: "\e931";
}

.icon-car:before {
    content: "\e932";
}

.icon-surface:before {
    content: "\e933";
}

.icon-mosaico:before {
    content: "\e934";
}

.icon-lista:before {
    content: "\e935";
}

.icon-picture:before {
    content: "\e936";
}

.icon-investment:before {
    content: "\e937";
}

.icon-leadership:before {
    content: "\e938";
}

.icon-land1:before {
    content: "\e939";
}

.icon-solid-like:before {
    content: "\e93a";
}

.icon-filter:before {
    content: "\e93b";
}

.icon-3d:before {
    content: "\e93c";
}

.icon-logo:before {
    content: "\e93d";
}

.icon-bath:before {
    content: "\e93e";
}

.icon-car2:before {
    content: "\e93f";
}

.icon-car3:before {
    content: "\e940";
}

.icon-measure:before {
    content: "\e941";
}

.icon-ico-sendmail:before {
    content: "\e942";
}

.icon-picture1:before {
    content: "\e943";
}

.icon-bed11:before {
    content: "\e944";
}

.icon-user:before {
    content: "\e945";
}

.icon-icon-dollar:before {
    content: "\e946";
}

.icon-land:before {
    content: "\e947";
}

.icon-dollar:before {
    content: "\e948";
}

.icon-building:before {
    content: "\e949";
}

.icon-weird-square:before {
    content: "\e94a";
}

.icon-celular1:before {
    content: "\e958";
}

.icon-lupa:before {
    content: "\e986";
}

.icon-equalizer:before {
    content: "\e992";
}

.icon-checkmark:before {
    content: "\ea10";
}

.icon-whatsapp:before {
    content: "\ea93";
}