.emprendimientos {
  background-color: $background-1;
  .lotes {
    padding-top: 2vh;
    padding-bottom: 5vh;
    span {
      // font-family: "LEMON MILK";
      font-size: 0.85rem;
      font-weight: 400;
    }
    .title-1 {
      margin: 2rem 0 7rem 0;
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
  .emprendimientos {
    .lotes {
      padding-top: 0rem;
      padding-bottom: 3rem;
      .container-fluid {
        max-width: 100%;
      }
      .title-1 {
        margin: 1rem 0 2rem 0;
      }
    }
  }
}
