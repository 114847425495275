.Emprendimiento {
    .data {
        .container-fluid {
            padding: 11rem 0 0 0;
            position: relative;
            .index-1 {
                z-index: -1;
                .line-vertical {
                    border-color: #0f0f0f;
                    opacity: 0.15;
                    z-index: 4;
                }
            }
            .see_description {
                color: #000;
            }
            .col-lg-4.col-xl-3 {
                width: 100%;
                .both {
                    // width: 33%;
                    width: 10%;
                    margin-right: 6.6%;
                    &.large {
                        width: 30%;
                    }
                }
            }
            .type {
                padding: 0.5rem 1.3rem;
                font-size: 1rem;
                font-weight: 500;
                text-transform: capitalize;
                border-radius: 15px;
                border: solid thin $primary-color-1;
                color: $primary-color-1;
            }
            .title-1,
            h2 {
                // margin: 0rem 0 2rem 0;
                .location {
                    color: $border-color-1;
                    font-weight: 500;
                    font-size: 23px;
                }
            }
            p {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 30px;
                width: 90%;
                color: #000;
            }
            .line {
                margin-top: 6vh;
                display: block;
                width: 100%;
                height: 1rem;
                background: $tertiary-color;
            }
            .btn-square {
                width: 3.5rem;
                height: 3.5rem;
                font-size: 1rem;
                line-height: 1;
                font-weight: 300;
                border: 2px solid;
                z-index: 99;
                &:hover {
                    background: $secondary-color !important;
                    border-color: $secondary-color;
                    i {
                        color: #fff;
                    }
                }
            }
            .item {
                font-weight: 400;
                .tick {
                    background: $primary-color-1;
                    max-width: 1.5rem;
                    max-height: 1.5rem;
                    min-width: 1.5rem;
                    min-height: 1.5rem;
                    border-radius: 100%;
                    i {
                        color: #fff;
                        font-size: 0.5rem;
                    }
                }
            }
            .both {
                margin-top: 2.3rem;
                h5 {
                    font-size: 1.5rem;
                    font-weight: 600;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 1.5rem;
                    font-weight: 200;
                }
            }
            .map {
                height: 60vh;
                object-fit: cover;
                object-position: center;
                margin-top: 6rem;
            }
        }
        &.dark {
            background-color: rgba($secondary-color, 1);
            .address,
            .type,
            p {
                color: #fff;
            }
            .item {
                color: #fff;
            }
            .both {
                h5,
                span {
                    color: #fff;
                }
            }
            .breadcrumb-item {
                color: #fff;
            }
        }
    }
}

@media (max-width: 992px) {
    .Emprendimiento {
        .data {
            padding-top: 4rem;
            .container-fluid {
                padding: 7vh 1rem 0 1rem;
                &.location-section {
                    padding-top: 0vh;
                }
                .type {
                    font-size: 1rem;
                }
                .title-1 {
                    margin: 2rem 0;
                    .location {
                        font-size: 1.2rem;
                    }
                }
                .col-lg-4.col-xl-3 {
                    .both,
                    .both.large {
                        width: 44% !important;
                        margin-right: 0;
                        h5,
                        span {
                            font-size: 1.1rem;
                        }
                    }
                }
                .btn {
                    margin-bottom: 2rem;
                }
                p {
                    width: 100%;
                    font-size: 1.1rem;
                    margin-top: 1.7rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}