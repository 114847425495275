.propiedad {
    .data {
        background-color: $background-1;
        padding-top: 15rem;
        overflow: hidden;
        max-width: 100vw;
        z-index: 100;
        .title-1 {
            margin-top: 1rem;
            margin-bottom: 1rem;
            // line-height: 40px;
            font-weight: 600;
            font-style: normal;
            font-size: 6rem;
            color: $secondary-color-2 !important;
            & .location {
                font-weight: 400;
                font-size: 23px;
                color: $secondary-color-2;
                i {
                    font-size: 1.5rem;
                    padding-right: 1rem;
                }
            }
            &.sub_title {
                font-weight: 500;
                color: $secondary-color-1;
                margin-top: 10vh;
            }
        }
        .div-iconos {
            .btn-medium-1 {
                margin-right: 2rem;
                font-weight: 400;
                border-color: $secondary-color-3;
                color: $secondary-color-2;
                i {
                    font-size: 1.3rem;
                    color: $secondary-color-3;
                }
                &:hover {
                    background-color: white;
                    border: solid thin $primary-color;
                    color: $primary-color;
                    i {
                        color: $primary-color;
                    }
                }
            }
        }
        .btn-medium-dark {
            &.small {
                padding: 0.4rem;
                color: #fff;
                margin-left: 1rem !important;
                .icon-solid-like {
                    color: transparent;
                    -webkit-text-stroke: 2px #fff;
                }
                i {
                    margin-right: 0rem;
                }
                &:hover,
                &.active {
                    background: transparent;
                    border-color: $primary-color;
                    i {
                        color: $primary-color;
                        -webkit-text-stroke: none !important;
                    }
                    .icon-solid-like {
                        -webkit-text-stroke: 0px !important;
                    }
                }
            }
            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }
        }
        .tag {
            padding: 0.5rem 1.3rem;
            font-size: 1rem;
            font-weight: 500;
            color: $secondary-color;
            background: #fff;
            text-transform: capitalize;
            min-width: 6rem;
            text-align: center;
            border-radius: 15px;
            border: solid thin $primary-color;
            &.primary-tag {
                background-color: transparent;
                color: $primary-color;
            }
            &.Alquiler {
                border: transparent 1px solid;
                background-color: $secondary-color-2;
                color: #fff;
            }
            &.temporario {
                background-color: #fff;
                border: 1px solid $secondary-color-2;
                color: $secondary-color-2;
            }
            &.Venta {
                background-color: $primary-color;
                color: #fff;
            }
        }
        .item {
            color: $secondary-color-2;
            font-weight: 400;
            font-size: 1.4rem;
            .tick {
                background-color: $primary-color;
                min-width: 1.5rem;
                min-height: 1.5rem;
                max-width: 1.5rem;
                max-height: 1.5rem;
                border-radius: 100%;
                position: relative;
                i {
                    color: #fff;
                    font-size: 0.5rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        p {
            font-size: 1.4rem;
            color: $secondary-color-1;
            margin-top: 3rem;
            line-height: 1.2;
            width: 90%;
            font-weight: 400;
            line-height: 30px;
        }
        .content-price {
            display: block;
            h3 {
                font-size: 2.5rem;
                font-weight: 500;
                color: $secondary-color-1;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                min-width: 30rem;
                // text-align: end;
                padding-left: 2.5rem;
                &::before {
                    content: "";
                    width: 1.5rem;
                    height: 1.5rem;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 11px;
                    border-radius: 100%;
                }
                &.Alquiler {
                    &::before {
                        background: $secondary-color-2;
                        color: #fff;
                        border: solid thin transparent;
                    }
                }
                &.temporario {
                    &::before {
                        background: #fff;
                        color: $secondary-color-2;
                        border: solid thin $secondary-color-2;
                    }
                }
                &.Venta {
                    &::before {
                        background: $primary-color;
                    }
                }
                &.min-size {
                    padding-left: 0;
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
        .global_view {
            height: 60vh;
            width: 100%;
            margin-top: 3rem;
        }
        .contact-main {
            form {
                .input-wrapper {
                    background: white;
                    border: none;
                    box-shadow: 0px 30px 60px #d2dbe266;
                }
            }
        }
        .mancha-after2::after {
            background: transparent radial-gradient(closest-side at 50% 50%, #d2dbe266 0%, #f3edec00 100%) 0% 0% no-repeat padding-box;
        }
        .separador-amenities {
            hr {
                height: 1px !important;
                // color: $secondary-color-2;
                background: $secondary-color-2;
                opacity: 1 !important;
            }
        }
    }
}

.property-data {
    border-radius: 50px;
    border: solid thin $secondary-color-2;
    padding: 2rem;
    background: white;
    box-shadow: 0px 20px 60px #dbdada;
}

.property-atributes {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 20px;
    margin-top: 1.5rem;
    padding: 2rem;
    color: $secondary-color-3;
    i {
        color: $secondary-color-1;
        &.icon-casa {
            font-size: 1.3rem;
        }
    }
    span {
        &:first-child {
            font-size: 1.4rem;
            // color: $secondary-color-3;
            // opacity: 0.8;
        }
        font-size: 1.4rem;
        margin-bottom: 0.3rem;
        display: block;
        font-weight: 400;
        line-height: 1.2;
        color: $secondary-color-2;
    }
}

.item-extra {
    margin-top: 0.5rem;
    width: 50%;
    // font-size: 1.5rem;
    // color: #c3c3c3;
    align-items: center;
    font-weight: 400;
    i {
        color: $secondary-color-1;
        text-align: center;
        font-size: 1.25rem;
        &.icon-gym {
            font-size: 0.7rem;
        }
        &.icon-home {
            font-size: 1.1rem;
            margin-right: 1.8rem !important;
            display: block;
        }
    }
}

.h3-amenities {
    font-size: 3rem;
    font-weight: 600;
    color: $secondary-color-2;
}

.amenites-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &>div:nth-child(2n) {
        // justify-self: end;
        margin-left: 3rem;
    }
}

@media (max-width: $breakpoint-laptop) {
    .property-data {
        border-radius: 0;
        border: none;
        padding: 1rem;
        margin-top: 3rem;
        .item-extra {
            width: 100%;
            justify-content: flex-start;
            span {
                font-size: 1rem;
            }
        }
    }
    .property-atributes {
        padding: 2rem .5rem;
        i {
            &.icon-casa {
                font-size: 1.1rem;
            }
        }
    }
    .propiedad {
        .data {
            padding-top: 12rem;
            .title-1 {
                line-height: 1 !important;
                font-size: 4rem !important;
                margin-top: 2rem;
                &.sub_title {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }
                .location {
                    font-size: 1.5rem;
                    margin: 0.5rem 0 4rem 0;
                }
            }
            .div-iconos {
                margin-bottom: 2rem;
            }
            .btn {
                padding: 0rem 1rem;
                font-size: 0.6rem;
                margin-right: 0.5rem;
            }
            .tags {
                margin-top: 2rem !important;
                margin-bottom: 3rem !important;
                .tag {
                    // padding: 0.3rem 1rem;
                    // margin-bottom: 4vh;
                    margin-top: 1rem;
                    font-size: 1rem;
                }
            }
            .item {
                font-size: 1.2rem;
                .tick {
                    width: 0.7rem;
                    height: 0.7rem;
                    border-radius: 100%;
                }
            }
            p {
                font-size: 1.3rem;
                width: 100%;
                margin-top: 1.5rem;
            }
            .content-price {
                h3 {
                    font-size: 2.5rem;
                    min-width: unset;
                    padding-left: 3rem;
                    padding-right: 3rem;
                }
            }
            .contact-main {
                pad: 4rem !important;
                form {
                    padding-top: 0vh !important;
                }
                h2 {
                    margin-top: 2rem;
                }
                .btn {
                    font-size: 0.9rem;
                    padding: 0 2rem;
                    padding-left: calc(2rem + 3rem);
                }
            }
        }
    }
    .item-extra {
        font-size: 1rem;
        overflow-x: scroll;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: auto;
        &:nth-child(4) {
            margin-bottom: 2vh;
        }
        i {
            width: 1.5rem;
            font-size: 1rem;
            &.icon-bathtub {
                font-size: 1.2rem;
            }
        }
    }
}