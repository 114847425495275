.Inicio {
    .lotes {
        .title-1 {
            font-size: 6rem;
        }
        min-height: 100vh;
        background-color: $background-1;
        text-align: center;
        padding-top: 10rem;
        position: relative;
        &>div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &>div:nth-child(1) {
                max-width: 1000px;
            }
        }
        p.paragraph-1.dark-blue {
            font-size: 1.75rem;
        }
        .double-btn {
            display: flex;
            margin: auto;
            width: 42vw;
            background-color: transparent;
            border-radius: 7px;
            overflow: hidden;
            margin-top: 4rem;
            border: 1px solid #0c446f;
            //   padding: 0.5rem;
            & div {
                border-left: solid 1px $secondary-color-1;
                margin: 3% 0;
            }
            & button {
                font-weight: 500;
                border: none;
                width: 100%;
                background-color: transparent;
                color: #888888;
                font-size: 1.6rem;
                &.btn-active {
                    color: $secondary-color-1;
                }
                &:hover {
                    cursor: pointer;
                    color: $background-3;
                }
                &:focus {
                    opacity: 1;
                    outline: none;
                }
                &:nth-child(1) {
                    padding: 1rem 3.5rem;
                }
                &:nth-child(3) {
                    padding: 1rem 3.5rem;
                }
            }
        }
        .owl-stage {
            display: flex;
        }
        .lotes-slider {
            padding: 4rem 0;
            position: relative;
            margin: 1rem 0;
            width: 100%;
            // min-height: 600px;
            transition: transform 1s, height 1s, width 1s;
            overflow: hidden;
            & .lotes-slider-item {
                // margin: 0 2rem;
                // min-height: 350px;
                max-width: 39vw;
                width: 100%;
                margin: auto;
                transition: transform 1s, height 1s, width 1s;
                position: relative;
                // overflow: hidden;
                & .operation-buttons {
                    position: absolute;
                    top: 2rem;
                    left: 2rem;
                    & .btn-rounded {
                        opacity: 0;
                        transition: opacity 0.3s;
                        padding: .8rem 2rem;
                        margin-right: 1rem;
                        background: $background-3;
                        font-size: 1rem !important;
                        &.Venta {
                            background: $primary-color;
                        }
                        &.Alquiler {
                            background-color: $secondary-color-2;
                            color: #fff;
                        }
                        &.temporario {
                            border: solid thin $secondary-color-2;
                        }
                    }
                }
                &:hover .btn-rounded {
                    opacity: 1;
                    // font-size: 1rem !important;
                    &.Venta {
                        background: $primary-color;
                    }
                    &.Alquiler {
                        color: #fff;
                        background-color: $secondary-color-2;
                    }
                    &.temporario {
                        border: solid thin $secondary-color-2;
                        color: $secondary-color-2;
                        background-color: #fff;
                    }
                }
                & .item-description {
                    position: absolute;
                    bottom: -40%;
                    left: -0.5px;
                    opacity: 0;
                    width: calc(100% + 1px);
                    background-color: #ffffff7e;
                    backdrop-filter: blur(9px);
                    border-radius: 0 0 30px 30px;
                    transition: bottom 0.5s, opacity 0.6s;
                    padding: 2rem 2.5rem;
                    text-align: start;
                    z-index: 10;
                    pointer-events: none;
                    & h4 {
                        font-size: 1.9rem;
                        color: $secondary-color-3;
                        font-weight: 500;
                    }
                    & .description {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        font-size: 18px;
                        color: $secondary-color-3;
                        margin-bottom: 1rem;
                        & img {
                            width: auto;
                        }
                        span {
                            // color: #525252;
                            color: $secondary-color-3;
                        }
                    }
                    & .cost {
                        font-size: 27px;
                        color: $primary-color;
                        font-weight: 600;
                    }
                    .cost-icon-wrap {
                        .arrow-link {
                            border-radius: 100%;
                            border: solid thin #ffffff;
                            padding: 20px 20px;
                            background-color: $secondary-color-2;
                            line-height: 0;
                            transition: .3s ease all;
                            img {
                                width: 1rem;
                            }
                            &:hover {
                                background-color: $background-3;
                                opacity: .8;
                            }
                        }
                        .favorite-wrap {
                            border-radius: 100%;
                            transition: .3s ease all;
                            border: solid thin #ffffff;
                            padding: 5px 12px;
                            background-color: $secondary-color-2;
                            &:hover,
                            &.active {
                                background-color: $background-3;
                                opacity: .8;
                            }
                        }
                    }
                }
                &:hover .item-description {
                    opacity: 1;
                    bottom: 0;
                    pointer-events: all;
                }
                @media (max-width: $breakpoint-laptop) {
                    min-height: 40vh;
                    max-height: 40vh;
                    margin-bottom: 9rem;
                    // padding-bottom: 5rem;
                    .btn-rounded {
                        opacity: 1;
                        width: 12rem;
                        left: calc(50% - 6rem);
                        padding: 1rem 1.5rem;
                    }
                    & .lotes-slider-img {
                        height: 450px;
                    }
                    & .item-description {
                        position: relative;
                        justify-content: center;
                        bottom: 0;
                        left: 0;
                        border-radius: 30px;
                        min-height: 450px;
                        transform: translateY(-100%);
                        opacity: 1;
                        backdrop-filter: blur(1.5px);
                        padding: 1.4rem;
                        padding-top: 4rem;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        &>h4 {
                            justify-content: center;
                        }
                        &>div {
                            flex-direction: column;
                        }
                        .space {
                            margin-top: 2rem;
                        }
                        .favorite-wrap {
                            margin: .5rem !important;
                            width: fit-content;
                            display: inline-block !important;
                            padding: 10px 12px !important;
                        }
                        .arrow-link {
                            position: relative;
                            margin: auto;
                            display: inline-block;
                            margin: .5rem !important;
                            margin-top: 1rem;
                            width: min-content;
                        }
                    }
                }
            }
            & .active-slider {
                // transform: scale(1.2);
            }
            & .lotes-slider-img {
                border-radius: 30px;
                width: 100%;
                box-shadow: 20px 60px 60px #dbdada;
                height: 50vh;
                object-fit: cover;
                object-position: center;
                &:hover {
                    // transform: scale(1.25);
                }
            }
            .slider-right-button,
            .slider-left-button {
                position: absolute;
                transform: translate(0%, -50%);
                font-size: 50px;
                padding: 0;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;
                background-position: center;
                background-color: transparent;
                border-radius: 5px;
                border: solid 0.5px $secondary-color-1;
                & span {
                    display: none;
                }
                &:hover {
                    background-color: $secondary-color-1;
                }
            }
            .slider-left-button {
                top: 35%;
                left: 22%;
                background-image: url("../../images/icons/left-arrow-icon.svg");
            }
            .slider-right-button {
                top: 35%;
                right: 22%;
                background-image: url("../../images/icons/right-arrow-icon.svg");
            }
            .slider-left-button:hover {
                background-image: url("../../images/icons/white-left-arrow-icon.svg");
            }
            .slider-right-button:hover {
                background-image: url("../../images/icons/white-right-arrow-icon.svg");
            }
            .py-10 {
                padding: 6.5rem 0;
            }
            @media (max-width: $breakpoint-laptop) {
                .slider-left-button {
                    left: 0;
                }
                .slider-right-button {
                    right: 0;
                }
            }
            .owl-item {
                transition: 1s ease all;
                // img {
                //     height: 55vh;
                // }
                &.active.center {
                    transform: scale(1.2);
                }
            }
            .btn-medium {
                margin: 6rem 0;
                font-size: 2rem;
                padding: 1rem 4rem;
            }
        }
    }
}

@media (max-width: 992px) {
    .Inicio {
        .lotes {
            .title-1 {
                font-size: 4rem;
                line-height: 1 !important;
            }
            .double-btn {
                width: 90%;
                button {
                    font-size: 1rem;
                    padding: .7rem .2rem !important;
                    // &:nth-child(1) {
                    //     padding: 1rem 2rem;
                    //     font-size: 1.5rem;
                    // }
                    // &:nth-child(3) {
                    //     padding: 1rem 2rem;
                    //     font-size: 1.5rem;
                    // }
                }
            }
            .lotes-slider {
                // margin-top: 0 !important;
                padding-top: 0 !important;
                .lotes-slider-item {
                    max-width: unset;
                    .operation-buttons {
                        z-index: 99;
                        left: auto;
                        right: auto;
                        width: 100%;
                        a {
                            margin: 0 .4rem !important;
                        }
                        .btn-rounded {
                            width: unset;
                            margin: 0rem .1rem !important;
                            padding: 0.5rem 1.2rem !important;
                            opacity: 1;
                        }
                    }
                }
                .owl-item {
                    transform: scale(0.8);
                    &.active.center {
                        transform: scale(0.9);
                    }
                }
                .btn-medium {
                    padding: 1rem 1rem;
                    font-size: 1.5rem;
                }
            }
        }
    }
}