.contact-card {
    border-radius: 20px;
    border: solid 1px $secondary-color-1;
    .content {
        span {
            font-size: 1.2rem;
            &:first-child {
                font-weight: 600;
                font-style: normal;
                font-size: 1.5rem;
            }
        }
        font-weight: 400;
        text-align: start;
        a {
            font-weight: 400;
        }
        a.wb {
            word-break: break-all;
        }
        @media (min-width: $bt-bp-md) and (max-width: $bt-bp-lg - 1px) {
            width: 50%;
        }
    }
}