.contacto {
    background-color: $background-1;
    padding: 0;
    padding-top: 20rem !important;
    .main {
        padding-top: 5rem;
        padding: 0;
        overflow: hidden;
        margin-bottom: 5rem !important;
        .image-wrapper {
            margin: auto;
            width: 100%;
            div {
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 70vh;
                object-fit: cover;
                border-radius: 30px;
            }
        }
    }
    .shadow-1 {
        box-shadow: 0px 60px 60px #d2dbe280;
    }
    .div-contactanos {
        .title-1 {
            font-size: 6rem;
            font-weight: 600;
            font-style: normal;
            color: $secondary-color-2;
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .contacto {
        padding-top: 10rem !important;
        .main {
            height: 45vh;
            .image-wrapper {
                padding: 0 1rem;
                div {
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    height: 40vh;
                    object-fit: cover;
                    border-radius: 30px;
                }
            }
        }
        .div-contactanos {
            .title-1 {
                font-size: 4rem;
                line-height: 1 !important;
                margin-bottom: 3rem !important;
            }
        }
    }
}