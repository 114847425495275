.property-card {
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    padding: 1rem;
    overflow: hidden;
    box-shadow: 0px 20px 30px #d2dbe280;
    // box-shadow: 10px 10px 30px #00000020;
    &:hover {
        .content-header {
            img {
                transform: scale(1.2);
            }
        }
    }
    .btn-rounded {
        font-weight: 400;
    }
    .Alquiler {
        background-color: $secondary-color-2;
        color: #fff;
    }
    .temporario {
        background-color: #fff;
        color: $secondary-color-2;
        border: 1px solid $secondary-color-2;
    }
    .Alquiler.temporario {
        border: solid thin $secondary-color-3;
    }
    .content-header {
        position: relative;
        overflow: hidden;
        border-radius: 25px;
        img {
            width: 100%;
            transition: 0.5s ease all;
            min-height: 30vh;
            max-height: 30vh;
            object-fit: cover;
            object-position: center;
        }
        .type-property {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #ffffff54;
            padding: 0.4rem 2rem;
            font-weight: 300;
            line-height: 1;
            padding-left: 2rem;
            color: #fff;
        }
    }
    .top-card {
        position: absolute;
        display: flex;
        z-index: 4;
        top: 2rem;
        left: 2rem;
        font-size: 0.8rem;
        padding-right: 1.5rem;
        flex-wrap: wrap;
        max-width: 80%;
        .operation-property {
            color: #fff;
            text-transform: uppercase;
            font-weight: 300;
            padding: 0.5rem 0rem 0.5rem 0rem;
        }
    }
    .content-body {
        padding: 1.5rem;
        position: relative;
        a {
            text-decoration: none;
        }
        .title {
            max-width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 2.5rem;
            font-weight: 400;
            color: $secondary-color-3;
            text-decoration: none;
            margin-bottom: 1rem;
        }
        .btn-like {
            display: flex;
            //   padding: 5px;
            justify-content: center;
            border: none;
            align-items: center;
            vertical-align: middle;
            background-color: transparent;
            transition: 0.5s ease all;
            width: min-content;
            position: absolute;
            top: 2.25rem;
            right: 2rem;
            border-radius: 5px;
            border: 1px solid $secondary-color-3;
            i {
                transition: 0.5s ease all;
                // color: $primary-color;
                color: transparent;
                -webkit-text-stroke: 1px $secondary-color-3;
                font-size: 1.5rem;
                padding: 0.2rem;
            }
            &:hover,
            &.active {
                // background: $tertiary-color;
                i {
                    color: $background-3;
                    -webkit-text-stroke: 1px $background-3;
                }
            }
        }
        .location {
            color: $secondary-color-3;
            font-weight: 400;
            opacity: 0.8;
            font-size: 1.6rem;
            i {
                font-size: 1.6rem;
            }
            margin-bottom: 1.2rem;
        }
        .details {
            padding: 1rem 0;
            &_item {
                font-size: 1.6rem;
                color: $tertiary-color-1;
                font-weight: 400;
                white-space: nowrap;
                i {
                    font-size: 1.5rem;
                    color: $tertiary-color-1;
                    &.icon-surface-terreno {
                        font-size: 1.2rem;
                        // margin-bottom: -10px;
                        // display: inline-flex;
                        // &::before {
                        //     line-height: 1;
                        // }
                        // line-height: 1rem;
                        // margin-top: 5px;
                    }
                    &.icon-bed11 {
                        font-size: 1.2rem;
                    }
                }
            }
        }
        &_bottom {
            margin-top: 1rem;
            .price {
                font-size: 2.5rem;
                font-weight: 600;
                color: $background-3;
                &.min-size {
                    font-size: 2.5rem;
                }
                span.custon-tooltip {
                    font-weight: 600;
                    span.custon-tooltip-text {
                        z-index: 1;
                        padding: 5px 7px;
                        display: none;
                        color: #fff;
                        background-color: #7a9dc4;
                        border-radius: 6px;
                        position: absolute;
                    }
                    &:hover {
                        span.custon-tooltip-text {
                            left: 0;
                            bottom: 40px;
                            display: block;
                            font-size: 22px;
                            font-weight: 300;
                            white-space: nowrap;
                        }
                        span.custon-tooltip-text::before {
                            width: 0;
                            height: 0;
                            content: "";
                            bottom: -7px;
                            position: absolute;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-top: 10px solid #7a9dc4;
                        }
                    }
                }
            }
            .btn-more {
                width: 4rem;
                padding: 0;
                height: 4rem;
                background: transparent;
                color: $secondary-color-1;
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                vertical-align: text-top;
                border-radius: 5px;
                border: 1px solid $secondary-color-1;
                line-height: 1;
                text-decoration: none;
                align-self: center;
                i {
                    color: $secondary-color-1;
                    font-size: 2rem;
                }
                &:hover {
                    background: $secondary-color-2;
                    border: 1px solid $secondary-color-2;
                    i {
                        color: #fff;
                    }
                }
            }
        }
        &.listed-content-style {
            height: 300px;
            padding-top: 3rem;
        }
    }
    &.list {
        height: 350px;
        &>a {
            width: 65%;
            //   height: 350px !important;
            //   max-height: 350px;
            align-self: center;
        }
        .top-card {
            top: 2rem;
        }
        .content-header {
            width: 40%;
            overflow: hidden;
            height: 100%;
            border-radius: 37px;
            img {
                // min-height: 350px !important;
                // max-height: 350px !important;
                overflow: hidden;
                height: 330px !important;
                min-height: 330px;
                max-height: 330px;
                // max-height: none;
            }
        }
        .content-body {
            width: 65%;
            // padding-left: 10rem;
            .title {
                font-size: 2.2rem;
            }
            .content-body_bottom {
                .price {
                    font-size: 2rem !important;
                    &.min-size {
                        font-size: 2rem !important;
                        .custon-tooltip {
                            // font-size: 2rem !important;
                        }
                    }
                }
            }
            .list-items {
                margin-top: 0 !important;
                .type-property {
                    color: #5b5b5b;
                    background-color: transparent;
                    border: 2px solid #5b5b5b;
                    border-radius: 5px;
                    line-height: unset;
                    font-size: 0.8rem;
                    letter-spacing: -0.2px;
                    // padding: 0.1rem 1.2rem;
                    border-radius: 10px;
                    padding: 0.48rem 1.5rem;
                    line-height: 1;
                    font-size: 16px;
                    font-weight: 400;
                    position: relative;
                    margin-bottom: 2rem;
                    margin-top: 0 !important;
                }
            }
            .details_item,
            .details i,
            .location {
                color: $tertiary-color-1;
                font-size: 18px;
                font-weight: 400;
            }
            .location {
                font-weight: 300;
            }
            &_bottom {
                .btn-more {
                    span {
                        height: 2.2rem;
                    }
                }
            }
            .btn-like {
                // display: none;
            }
        }
    }
    &.dark {
        .content-header {
            &::before {
                background: linear-gradient( 320deg, transparent 90%, $secondary-color 7%);
            }
        }
        .content-body {
            .title,
            .location {
                color: #fff;
            }
            .details {
                &_item {
                    color: #797979;
                    i {
                        color: #797979;
                    }
                }
            }
            &_bottom {
                .price {
                    color: #fff;
                }
            }
        }
    }
}

.div-card-list {
    padding: 0 5rem !important;
}

@media (max-width: 1200px) {
    &.list {
        &>a {}
        .top-card {}
        .content-header {
            img {}
        }
        .content-body {
            .title {}
            .content-body_bottom {
                .price {
                    .custon-tooltip {}
                }
            }
            .list-items {
                .type-property {}
            }
            .details_item,
            .details i,
            .location {}
            .location {}
            &_bottom {
                .btn-more {
                    span {}
                }
            }
            .btn-like {}
        }
    }
    .div-card-list {
        padding: 0 3rem !important;
    }
}

@media (max-width: 992px) {
    .property-card {
        height: 100%;
        .top-card {
            padding-right: 1.2rem;
            .operation-property {
                font-size: 0.7rem;
                padding: 0.4rem 0rem 0.4rem 1rem;
            }
            .btn-like {
                i {
                    font-size: 1rem;
                }
            }
        }
        .content-header {
            .type-property {
                font-size: 0.7rem;
            }
        }
        .content-body {
            padding: 0.8rem 0.8rem 0 0.8rem;
            .title {
                font-size: 1.3rem;
                margin-bottom: 0.8rem;
            }
            .location {
                font-size: 0.9rem;
                margin-bottom: 0.8rem;
                i {
                    font-size: 0.8rem;
                }
            }
            .details {
                padding: .0rem 0;
                &_item {
                    font-size: 0.9rem !important;
                    line-height: 1.2;
                    display: flex;
                    align-items: flex-end;
                    i {
                        font-size: 0.8rem;
                        &.icon-surface-terreno {
                            font-size: 1rem;
                        }
                        &.icon-bed11 {
                            font-size: .8rem;
                        }
                    }
                }
            }
            &_bottom {
                margin-top: 1.3rem;
                padding-bottom: 0.8rem;
                .price {
                    font-size: 2rem !important;
                    &.min-size {
                        font-size: 2rem !important;
                        .custon-tooltip {
                            font-size: 2rem !important;
                        }
                    }
                }
                .btn-more {
                    width: 3rem;
                    height: 3rem;
                }
            }
            .btn-like {
                right: 1rem;
                top: 1rem;
            }
        }
    }
    &.list {
        &>a {}
        .top-card {}
        .content-header {
            img {}
        }
        .content-body {
            .title {}
            .content-body_bottom {
                .price {
                    .custon-tooltip {}
                }
            }
            .list-items {
                .type-property {}
            }
            .details_item,
            .details i,
            .location {}
            .location {}
            &_bottom {
                .btn-more {
                    span {}
                }
            }
            .btn-like {}
        }
    }
    .div-card-list {
        padding: 1rem !important;
    }
}