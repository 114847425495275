.nosotros {
    .main {
        background-color: $background-1;
        padding: 15rem 0 10rem 0;
        .container-fluid {
            .quote-text {
                margin-bottom: 7rem;
                @media (max-width: $bt-bp-lg - 1px) {
                    margin-bottom: 4rem;
                }
                .breadcrumb-wrapper {
                    margin-top: 2rem;
                }
                @media (min-width: 959px) {
                    .quote-icon {
                        display: block !important;
                    }
                }
                .text-content {
                    padding-left: 2rem;
                    h1.title-1 {
                        font-size: 5.6rem;
                        // font-weight: 600;
                        color: $secondary-color-2;
                        font-weight: 600;
                        font-style: normal;
                        margin-top: 2rem !important;
                        @media (min-width: 992px) and (max-width: 1089px) {
                            font-size: 3rem;
                            line-height: 4.5rem !important;
                        }
                        @media (max-width: $breakpoint-tablet) {
                            font-size: 2.5rem;
                            line-height: 40px !important;
                        }
                    }
                    //   h2.subtitle {
                    //     font-weight: 500;
                    //     margin: 57px 0 28px 0;
                    //     color: $secondary-color-2;
                    //   }
                    p.paragraph-1 {
                        max-width: 610px;
                        font-weight: 500 !important;
                        font-size: 2rem;
                        margin-top: 4rem !important;
                        font-weight: normal;
                        font-style: normal;
                        color: $secondary-color-2;
                    }
                }
            }
        }
        .mobile-logo {
            @media (min-width: 1230px) {
                display: flex !important;
                .logo-rotation {
                    z-index: 1;
                    position: absolute;
                    top: 0;
                    right: 64%;
                    transform: translate(50%, -50%);
                }
            }
        }
        .who-are-us {
            .text-content {
                h3.subtitle {
                    font-weight: 600;
                    color: $background-3;
                    font-weight: bold;
                    font-style: normal;
                    font-size: 2rem;
                }
                p.paragraph-1 {
                    margin: 30px 0 30px 0;
                    //   max-width: 450px;
                    font-size: 2rem;
                    color: $secondary-color-2;
                    font-weight: 500;
                    font-style: normal;
                }
                h3 {
                    font-size: 2rem;
                    font-weight: bold;
                    font-style: normal;
                    color: $secondary-color-2;
                }
                @media (max-width: $bt-bp-lg - 1px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            @media (min-width: $bt-bp-lg - 1px) {
                .image1 {
                    margin-top: 2rem;
                }
            }
        }
        .image1,
        .image2 {
            border-radius: 30px;
            overflow: hidden;
            div {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 100%;
                animation: rotating 15s alternate infinite linear;
            }
        }
        .image1 {
            width: 65%;
            height: 606px;
            margin-left: 4rem;
            // margin-left: 6rem;
        }
        .image2 {
            max-width: 90%;
            height: 711px;
            @media (min-width: $breakpoint-laptop + 1px) {
                margin-top: -70px;
            }
        }
        @media (max-width: $bt-bp-xl + 1px) {
            .image1,
            .image2 {
                height: 660px;
            }
        }
    }
}

@media (max-width: $breakpoint-laptop) {
    .nosotros {
        .main {
            padding: 12rem 0 10rem 0;
            .container-fluid {
                .quote-text {
                    .breadcrumb-wrapper {}
                    .text-content {
                        h1.title-1 {
                            text-align: left;
                        }
                        p.paragraph-1 {
                            text-align: left;
                            font-size: 1.5rem;
                            margin-bottom: 4rem !important;
                        }
                    }
                }
            }
            .mobile-logo {
                display: none;
            }
            .who-are-us {
                .text-content {
                    h3.subtitle {}
                    p.paragraph-1 {
                        font-size: 1.5rem;
                    }
                    h3 {
                        font-size: 1.5rem;
                    }
                }
            }
            .image1,
            .image2 {
                div {}
            }
            .image1 {
                margin: 0;
                width: 100%;
            }
            .image2 {
                .image1,
                .image2 {}
            }
        }
        .main {
            .image1,
            .image2 {
                height: 400px;
                max-width: 100% !important;
            }
        }
    }
}

@keyframes rotating {
    0% {
        -ms-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -ms-transform: scale(1.25);
        -moz-transform: scale(1.25);
        -webkit-transform: scale(1.25);
        -o-transform: scale(1.25);
        transform: scale(1.25);
    }
}