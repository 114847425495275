.breadcrumb-wrapper {
  width: max-content;
  height: min-content;
  padding: 10px 15px;
  border: solid thin $secondary-color-2;
  border-radius: 15px;
  font-weight: 600;
  font-style: normal;

  & a {
    text-decoration: none !important;
  }

  & ol {
    margin: 0;
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding: 0rem;
  font-size: 1.25rem;
  color: $secondary-color-2;
  padding: 0 1rem 0 0.5rem;
  // font-weight: 600;
  font-weight: 600;
  font-style: normal;
  &::before {
    content: ">";
    color: $secondary-color-2;
    padding: 0 1rem 0 0;
    line-height: 25px;
    // font-weight: 600;
  }
  // a {
  //   padding-left: 0rem;
  //   font-size: 1rem;
  //   color: $primary-color;
  // }
}

.breadcrumb-item {
  font-size: 1rem;
  a {
    font-size: 1.25rem;
    color: $secondary-color-2;
    // font-weight: 600;
    padding: 0 1rem;
    font-weight: 600;
    font-style: normal;
  }
}

@media (max-width: $breakpoint-tablet) {
  .breadcrumb-wrapper {
    padding: 5px 10px;
    border-radius: 15px;
    // width: auto;
  }

  & ol {
    li {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .breadcrumb-item + .breadcrumb-item {
    padding: 0rem;
    font-size: 0.75rem;
    color: $tertiary-color-1;
    // padding: 0 1rem 0 0.5rem;

    &::before {
      content: ">";
      color: $tertiary-color-1;
      font-size: 13px;
      padding: 5px;
    }
    // a {
    //   padding-left: 0rem;
    //   font-size: 1rem;
    //   color: $primary-color;
    // }
  }

  .breadcrumb-item {
    font-size: 1rem;
    a {
      font-size: 0.75rem;
      color: $tertiary-color-1;
      // font-weight: 400;
      padding: 0 5px;
    }
  }
}

// .breadcrumb-item {
//   font-size: 0.9rem;
//   a {
//     font-size: 0.9rem;
//   }
// }
// .breadcrumb-item + .breadcrumb-item {
//   font-size: 0.9rem;
//   &::before {
//     padding: 0 0.3rem;
//   }
//   a {
//     font-size: 0.9rem;
//   }
// }
