.Inicio {
  .tasaciones {
    .title-1 {
      font-size: 6.4rem;
      line-height: 6rem !important;
    }
    // min-height: 85vh;
    padding: 5vh 0 25vh 0;
    background-color: $background-1;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 100;
    &::after {
      top: 20%;
      left: -7%;
    }
    .title-wrapper {
      // max-width: 700px;
    }
    .right-wrap {
      // max-width: 700px;
      .right-content {
        align-items: center;
        h3.title-2 {
          margin: 2rem 0 5rem 0;
          font-weight: normal !important;
        }
        .btn-rounded {
          width: 400px;
          padding: 1.25rem 3rem;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .Inicio {
    .tasaciones {
      .title-1 {
        text-align: center;
        // font-size: ;
      }
      &::after {
      }
      .title-wrapper {
      }

      .right-wrap {
        .right-content {
          h3.title-2 {
          }
          .btn-rounded {
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .Inicio {
    .tasaciones {
      padding: 0vh 0 5vh 0;
      .title-1 {
        font-size: 4rem;
        line-height: 4rem !important;
      }
      &::after {
      }
      .title-wrapper {
      }

      .right-wrap {
        .right-content {
          h3.title-2 {
          }
          .btn-rounded {
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .Inicio {
    .tasaciones {
      .title-1 {
        font-size: 3rem;
        line-height: 3rem !important;
      }
      &::after {
      }
      .title-wrapper {
      }

      .right-wrap {
        .right-content {
          h3.title-2 {
          }
          .btn-rounded {
            width: 100% !important;
            font-size: 1.5rem;
            padding: 1rem;
          }
        }
      }
    }
  }
}
